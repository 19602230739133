import {
  Breadcrumb,
  Layout,
  Menu,
  Input,
  Button,
  Typography,
  Space,
  Tooltip,
  Dropdown,
  Divider,
} from 'antd';
import './ContentContainer.css';
import Icon, {
  PlusOutlined,
  HistoryOutlined,
  BellOutlined,
  UserOutlined,
  LogoutOutlined,
  ProfileOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { useUnit } from 'effector-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  $isDarkTheme,
  $isTablet,
  $language,
  $userInfo,
  changeDarkThemeEv,
  changeLanguageEv,
  logoutEv,
} from '../../models/authModel/index.js';
import { wsSendDarkTheme } from '../../utils/webSocketConfig.js';
import logo from '../../assets/logo.svg';
import ReportModal from '../ReportModal/ReportModal.jsx';
import { openModalEv } from '../../models/reportModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import QalaAI from './QalaAI.jsx';
import { setMapLoadedEv } from '../../models/mapModel/index.js';
import {
  $showPresets,
  changeShowModalEv,
  changeShowPresetsEv,
} from '../../models/dataPresetsModel/index.js';
import BusinessTreeSelect from '../BusinessTreeSelect/BusinessTreeSelect.jsx';
import tezber_logo from '../../assets/tezber_logo.png';
import {
  $isPreset,
  toggleIsPresetEv,
  toggleProfileModalEv,
} from '../../models/rbpLiteModel/index.js';

const { Title } = Typography;
const { Header, Content, Sider } = Layout;
const { Search } = Input;
const sideMenuItems = [
  {
    key: 'obzor',
    icon: <PlusOutlined />,
    label: 'Обзор',
  },
  {
    key: 'sravnenie',
    icon: <PlusOutlined />,
    label: 'Сравнение',
  },
  {
    key: 'gipoteza',
    icon: <PlusOutlined />,
    label: 'Гипотоеза',
  },
];
// const breadCrumbItems = [
//   {
//     title: 'Dashboards',
//   },
//   {
//     title: 'Explore',
//   },
// ];

function SunSvg() {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3V4M12 20V21M4 12H3M6.31412 6.31412L5.5 5.5M17.6859 6.31412L18.5 5.5M6.31412 17.69L5.5 18.5001M17.6859 17.69L18.5 18.5001M21 12H20M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function SunIcon() {
  return <Icon component={SunSvg} />;
}

const noPaddingPages = [
  '/public',
  '/chat',
  '/chat-v2',
  '/banking',
  '/crowdfunding',
  '/realestate',
  '/elnet',
  '/heatsys',
  '/gassup',
  '/watersup',
  '/landrules',
  '/business-protection',
  '/business-plan',
  '/trademark',
  '/licensing',
  '/ip-rights',
];

function ContentContainer({ children }) {
  const isTablet = useUnit($isTablet);
  const logout = useUnit(logoutEv);
  // const darkMode = useUnit($userInfo)?.dark_theme;
  const darkMode = useUnit($isDarkTheme);
  const changeDarkTheme = useUnit(changeDarkThemeEv);
  const language = useUnit($language);
  const changeLanguage = useUnit(changeLanguageEv);
  const userInfo = useUnit($userInfo);
  const openModal = useUnit(openModalEv);
  const navigate = useNavigate();
  const changeShowPresets = useUnit(changeShowPresetsEv);
  const showPresets = useUnit($showPresets);
  const changeShowModal = useUnit(changeShowModalEv);
  const { pathname } = useLocation();
  // FIXME Lite switch state
  const isPreset = useUnit($isPreset);
  const toggleIsPreset = useUnit(toggleIsPresetEv);
  const toggleProfileModal = useUnit(toggleProfileModalEv);

  const toggleTheme = () => {
    wsSendDarkTheme(!darkMode);
    setMapLoadedEv(false);

    const link = !darkMode
      ? 'mapbox://styles/mapbox/dark-v11'
      : 'mapbox://styles/mapbox/streets-v12';
    if (window.map) {
      window.map.setStyle(link);
    }
    changeDarkTheme();
  };

  const profileMenu = [];

  if (userInfo?.perms?.includes('admin')) {
    profileMenu.push({
      key: 'userList',
      label: (
        <>
          <ProfileOutlined />{' '}
          {ru_en_page_dictionary.profile_user_list[language]}
        </>
      ),
    });
    profileMenu.push({
      key: 'requests-list',
      label: (
        <>
          <ProfileOutlined />{' '}
          {ru_en_page_dictionary.profile_request_list[language]}
        </>
      ),
    });
  }

  if (pathname === '/lite') {
    profileMenu.push({
      key: 'profile',
      label: (
        <>
          <UserOutlined />
          Личный кабинет
        </>
      ),
    });
  }
  profileMenu.push({
    key: 'logout',
    label: (
      <>
        <LogoutOutlined /> {ru_en_page_dictionary.profile_logout[language]}
      </>
    ),
  });

  const onDropdownClick = ({ key }) => {
    if (key === 'logout') {
      logout();
    }
    if (key === 'userList') {
      navigate('/user-list');
    }
    if (key === 'requests-list') {
      navigate('/requests-list');
    }
    if (key === 'profile') {
      toggleProfileModal(true);
    }
  };

  return (
    <Layout
      className="container"
      {...(pathname.includes('egov')
        ? pathname.includes('certificates') || pathname.includes('tax-modes')
          ? {
              style: {
                background: 'linear-gradient(180deg, #0C1322, #1C1C1C)',
                height: '100%',
                minHeight: '100svh',
              },
            }
          : { style: { background: '#2f2f2f' } }
        : {})}
    >
      {!isTablet &&
      !noPaddingPages.includes(pathname) &&
      !pathname.includes('egov') ? (
        <Header className="header">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/* <div>Logo</div> */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link
                // to={pathname === '/tezber' ? 'https://tezber.kz/' : '/root'}
                // target={pathname === '/tezber' ? '_blank' : '_self'}
                to="/rbp"
              >
                {/* {pathname === '/tezber' ? ( */}
                {/*  <img */}
                {/*    src={tezber_logo} */}
                {/*    style={{ */}
                {/*      height: '40px', */}
                {/*    }} */}
                {/*  /> */}
                {/* ) : ( */}
                <Title
                  style={{
                    margin: 0,
                    lineHeight: '22px',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}
                  level={1}
                >
                  RESILIENT BUSINESS PLATFORM
                </Title>
                {/* )} */}
              </Link>
              <Divider
                type="vertical"
                style={{
                  height: '20px',
                  margin: '0px 20px',
                  borderInlineStart: '1px solid #D3D3D3',
                }}
              />
              <Link
                to="https://qala-ai.com/"
                target="_blank"
                style={{
                  height: 'unset',
                  lineHeight: 'normal',
                  color: darkMode ? 'white' : 'black',
                }}
              >
                <QalaAI />
                {/* <img src={logo} /> */}
              </Link>
              {/* <Title */}
              {/*  style={{ margin: 0, lineHeight: '9px', fontSize: '8px' }} */}
              {/*  level={5} */}
              {/* > */}
              {/*  CITY PLATFORM */}
              {/* </Title> */}
            </div>
            {/* <Breadcrumb items={breadCrumbItems} style={{ marginLeft: '24px' }} /> */}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '8px',
              lineHeight: 'unset',
            }}
          >
            {/* <Search className="search" placeholder="Search..." /> */}
            {/* <Button icon={<HistoryOutlined />} shape="circle" /> */}
            {/* <Button icon={<BellOutlined />} shape="circle" /> */}
            {/* <Button icon={<LayoutOutlined />} shape="circle" /> */}
            {pathname !== '/tezber' && pathname !== '/lite' ? (
              <BusinessTreeSelect />
            ) : (
              ''
            )}
            {/* <Button */}
            {/*  style={{ */}
            {/*    border: 'unset', */}
            {/*    color: showPresets */}
            {/*      ? '#56b7e8' */}
            {/*      : darkMode */}
            {/*        ? '#D3D3D3' */}
            {/*        : '#717171', */}
            {/*  }} */}
            {/*  className="no_bg" */}
            {/*  onClick={changeShowPresets} */}
            {/* > */}
            {/*  {showPresets */}
            {/*    ? ru_en_page_dictionary.hide_presets[language] */}
            {/*    : ru_en_page_dictionary.show_presets[language]} */}
            {/* </Button> */}
            {userInfo?.perms?.includes('admin') &&
            pathname !== '/tezber' &&
            pathname !== '/lite' ? (
              <Button
                style={{ border: 'unset' }}
                className="no_bg"
                onClick={changeShowModal}
              >
                {ru_en_page_dictionary.presets[language]}
              </Button>
            ) : (
              ''
            )}
            {/* <Button className="report_button no_bg" onClick={openModal}> */}
            {/*  {ru_en_page_dictionary.report_button[language]} */}
            {/* </Button> */}
            {/* <ReportModal /> */}
            {pathname === '/lite' ? (
              <Button
                type="primary"
                onClick={toggleIsPreset}
                disabled={userInfo?.presets?.rbp_lite?.length === 0}
              >
                {isPreset
                  ? ru_en_page_dictionary.lite_new_location[language]
                  : ru_en_page_dictionary.lite_last_location[language]}
              </Button>
            ) : (
              ''
            )}
            <Divider
              type="vertical"
              style={{
                margin: 'unset',
                height: '28px',
              }}
            />
            <Button
              className="no_bg"
              icon={<SunIcon />}
              shape="circle"
              onClick={toggleTheme}
              style={{ border: 'unset' }}
            />
            {userInfo?.perms?.includes('admin') ? (
              <Dropdown
                placement="bottom"
                menu={{
                  items: [
                    {
                      key: 'ru',
                      label: 'Русский',
                    },
                    // {
                    //   key: 'kaz',
                    //   label: 'Қазақ',
                    // },
                    {
                      key: 'en',
                      label: 'English',
                    },
                  ],
                  onClick: changeLanguage,
                }}
              >
                <div className="language_box">
                  {language === 'ru' ? 'Рус' : 'En'}
                </div>
              </Dropdown>
            ) : (
              ''
            )}
            <Dropdown
              trigger="click"
              menu={{ items: profileMenu, onClick: onDropdownClick }}
              placement="bottomRight"
            >
              <div className="profile_box">
                <UserOutlined style={{ marginRight: '5px' }} />
                {userInfo?.email}
                <DownOutlined style={{ marginLeft: '5px' }} />
              </div>
            </Dropdown>
          </div>
        </Header>
      ) : (
        ''
      )}
      {/* <Layout hasSider> */}
      {/*  <Sider className="sider"> */}
      {/*    <Menu mode="vertical" items={sideMenuItems} /> */}
      {/*  </Sider> */}
      <Content
        className="content"
        style={{
          padding: noPaddingPages.includes(pathname) ? 'unset' : '1rem',
        }}
      >
        {children}
      </Content>
      {/* </Layout> */}
    </Layout>
  );
}

export default ContentContainer;
