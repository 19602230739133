import {
  putFilteredZoom8HexagonsEv,
  putZoom8HexagonsEv,
} from '../models/zoom8Model/index.js';
import {
  mutateZoom9PeopleEv,
  putFilteredZoom9HexagonsEv,
  putZoom9HexagonsEv,
  putZoom9StartPeopleDataEv,
} from '../models/zoom9Model/index.js';
import {
  putFilteredZoom10HexagonsEv,
  putZoom10HexagonsEv,
} from '../models/zoom10Model/index.js';
import { hideLoaderEv, showLoaderEv } from '../models/webSocketModel/index.js';
import {
  putChartDataEv,
  putCityIndexEv,
  putFilteredChartDataEv,
  putFilteredIndexCardsDataEv,
  putIndexCardsDataEv,
} from '../models/indexCardsModel/index.js';
import { setUserInfoEv, updateFavoritesEv } from '../models/authModel/index.js';
import { cookies } from '../api/axiosinstance.js';
import {
  changeRbpLayerEv,
  putSearchOptionsEv,
} from '../models/mapModel/index.js';
import { putIsochroneDataEv } from '../models/isochroneModel/index.js';
import { putBlockedBucketsEv } from '../models/gradientModel/index.js';
import {
  applyPresetFiltersEv,
  getDataPresetsFx,
} from '../models/dataPresetsModel/index.js';
import { putEconomicsDataEv } from '../models/economicsModel/index.js';
import { putSocdemDataEv } from '../models/socdemModel/index.js';
import {
  clearChartFiltersEv,
  clearGradientEv,
} from '../models/activeFiltersModel/index.js';
import {
  putD9TableEv,
  putIsochroneTotalEv,
  putKrishaObjectsEv,
  putLiteAggregatesEv,
  putLiteHexagonsEv,
  putRoadsEv,
} from '../models/rbpLiteModel/index.js';
import { openPaymentUrlEv } from '../models/rbpLandingModel/index.js';

const favFiledDict = {
  rcp: 'favorites',
  rbp: 'rbp_favorites',
  'rbp/chart': 'rbp_chart_favorites',
};

const anonRoutes = ['/payment', '/main'];

export function wsOnMessageHandler(message) {
  message = JSON.parse(message.data);
  console.log('WEBSOCKET:: wsOnMessageHandler: message', message);

  switch (message.action) {
    case 'users/login': {
      if (message.response.includes('expired token')) {
        cookies.remove('access_token', { path: '/' });
        window.location.replace('/login');
      }
      break;
    }
    case 'rcp/get_polygons':
      switch (message.part) {
        case 'zoom_8':
          // putZoom8HexagonsEv(message.response.polygons);
          // putBlockedBucketsEv({
          //   zoom: 'zoom_8',
          //   value: message.response.gradient,
          // });
          break;
        case 'zoom_9':
          // putZoom9HexagonsEv(message.response.polygons);
          // putBlockedBucketsEv({
          //   zoom: 'zoom_9',
          //   value: message.response.gradient,
          // });
          break;
        case 'zoom_10':
          window.download_end = performance.now();
          window.mutate_start = performance.now();
          putZoom10HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_10',
            value: message.response.gradient,
          });
          getDataPresetsFx();
          // hideLoaderEv();
          break;
        case 'city_total':
          putIndexCardsDataEv(message.response);
          putChartDataEv(message.response);
          putCityIndexEv(message.response.index);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'rbp/get_polygons':
      switch (message.part) {
        case 'city_data':
          putIndexCardsDataEv(message.response);
          putChartDataEv(message.response);
          putCityIndexEv(message.response.index);
          break;
        case 'zoom_data':
          window.download_end = performance.now();
          window.mutate_start = performance.now();
          // if (message.response.polygons.length === 4492) {
          //   putZoom9HexagonsEv(
          //     message.response.polygons.splice(
          //       0,
          //       Math.ceil(message.response.polygons.length / 2)
          //     )
          //   );
          // } else {
          // putZoom9HexagonsEv(
          //   message.response.polygons.filter((item) =>
          //     bostandyk.includes(item.id)
          //   )
          // );
          putZoom9HexagonsEv(message.response.polygons);
          getDataPresetsFx();
          // }
          break;
        case 'diagram_data':
          putSocdemDataEv(message.response[1]);
          putEconomicsDataEv(message.response[2]);
          setTimeout(() => {
            applyPresetFiltersEv();
          }, 1000);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'rcp/get_polygons_filtered':
      switch (message.part) {
        case 'zoom_8':
          putFilteredZoom8HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_8',
            value: message.response.gradient,
          });
          break;
        case 'zoom_9':
          putFilteredZoom9HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_9',
            value: message.response.gradient,
          });
          break;
        case 'zoom_10':
          putFilteredZoom10HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_10',
            value: message.response.gradient,
          });
          hideLoaderEv();
          break;
        case 'city_total':
          putFilteredIndexCardsDataEv(message.response);
          putFilteredChartDataEv(message.response);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'rbp/get_polygons_filtered':
      clearGradientEv();
      clearChartFiltersEv();
      changeRbpLayerEv('index');
      switch (message.part) {
        case 'city_data':
          putIndexCardsDataEv(message.response);
          putChartDataEv(message.response);
          putCityIndexEv(message.response.index);
          break;
        case 'zoom_data':
          window.download_end = performance.now();
          window.mutate_start = performance.now();
          putZoom9HexagonsEv(message.response.polygons);
          break;
        case 'diagram_data':
          putSocdemDataEv(message.response[1]);
          putEconomicsDataEv(message.response[2]);
          break;
      }
      break;
    case 'users/info':
      if (
        message.response === 'Anonymous user' &&
        !anonRoutes.includes(window.location.pathname)
      ) {
        window.location.replace('/login');
      }
      // if (!window.location.pathname.includes('payment'))
      setUserInfoEv(message.response);
      break;
    case 'map/get_point':
      putSearchOptionsEv(message.response);
      break;
    case 'map/get_isochrone':
      const id = `isochrone_lat_${message.payload.lat}_lon_${message.payload.lon}`;
      putIsochroneDataEv({ response: message.response, id });
      break;
    case 'user/favorite/add':
    case 'user/favorite/remove':
      {
        const field = favFiledDict[message.payload.api];
        updateFavoritesEv({
          field,
          value: message.response,
        });
      }
      break;
    case 'rbp/get_people_filtered':
      {
        mutateZoom9PeopleEv(message.response);
        // changeRbpLayerEv('socdem');
        if (message.payload.zoom_ids.length === 0) {
          putZoom9StartPeopleDataEv(message.response);
        }
      }
      break;
    case 'rbp_lite/get_isochrone':
      {
        putLiteHexagonsEv(message.response.zooms);
        putIsochroneTotalEv(message.response.isochrone);
        putLiteAggregatesEv(message.response.aggregates);
        putD9TableEv(message.response.d9_table);
        putKrishaObjectsEv(message.response.krisha_objects);
        putRoadsEv(message.response.traffic_lines);
      }
      break;
    case 'payments/user/create':
    case 'payments/anon/create':
      openPaymentUrlEv(message.response);
      break;
    default:
      break;
  }
}

export function wsGetAlmatyPolygons() {
  showLoaderEv();
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'rcp/get_polygons',
      partial: true,
    })
  );
}

export function wsSendAddFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        api: 'rcp',
        index_ids: indexes,
      },
    })
  );
}
export function wsSendRemoveFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        api: 'rcp',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendDarkTheme(dark_theme) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/set_dark_theme',
      payload: {
        enable: dark_theme,
      },
    })
  );
}

export function wsGetSearchOptions(place) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'map/get_point',
      payload: {
        address: place,
      },
      partial: false,
    })
  );
}

export function wsGetIsochrone(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'map/get_isochrone',
      payload: {
        lat: payload.coordinates ? payload.coordinates[1] : null,
        lon: payload.coordinates ? payload.coordinates[0] : null,
        time: payload.time ? payload.time : 5,
        traffic: payload.traffic
          ? payload.type === 'walk'
            ? false
            : payload.traffic
          : false,
        type: payload.type ? payload.type : 'walk',
      },
    })
  );
}

export function wsGetFilteredPolygons(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rcp/get_polygons_filtered',
      payload: {
        region_ids: payload.region_ids ? payload.region_ids : [],
        index_ids: payload.index_ids ? payload.index_ids : [],
      },
      partial: true,
    })
  );
}

export function wsGetRBPData(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rbp/get_polygons',
      payload: {
        business: payload?.business ? payload.business : '401',
        index_ids: payload?.index_ids ? payload.index_ids : [],
        zoom_ids: payload?.zoom_ids ? payload.zoom_ids : [],
      },
      partial: true,
    })
  );
}

export function wsSendRBPAddFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        api: 'rbp',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendRBPRemoveFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        api: 'rbp',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendRBPAddFavoritesChart(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        api: 'rbp/chart',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendRBPRemoveFavoritesChart(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        api: 'rbp/chart',
        index_ids: indexes,
      },
    })
  );
}

export function wsGetRBPFilteredData(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rbp/get_polygons_filtered',
      payload: {
        business: payload?.business ? payload.business : '401',
        index_ids: payload?.index_ids ? payload.index_ids : [],
        zoom_ids: payload?.zoom_ids ? payload.zoom_ids : [],
        groups: payload?.groups ? payload.groups : [],
      },
      partial: true,
    })
  );
}

// FIXME RBP Socdem click on charts
export function wsGetPeopleFiltered(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rbp/get_people_filtered',
      payload: {
        working: payload?.working ? payload.working : false,
        zoom_ids: payload?.zoom_ids ? payload.zoom_ids : [],
        groups: payload?.groups ? payload.groups : [],
      },
    })
  );
}

export function wsGetLiteData(payload) {
  const zoom_id = payload.zoom_id || payload.location_id || '8920e60acd7ffff';

  window.ws.send(
    JSON.stringify({
      action: 'rbp_lite/get_isochrone',
      partial: true,
      payload: {
        zoom_id,
        business: payload?.business ? payload.business : 1,
        sub_business: payload?.sub_business ? payload.sub_business : 1,
      },
    })
  );
}

export function wsSendPayment(payload) {
  window.wsp.send(
    JSON.stringify({
      action: 'payments/anon/create',
      payload,
    })
  );
}

export const wsSendPaymentUser = (payload) => {
  window.ws.send(
    JSON.stringify({
      action: 'payments/user/create',
      payload,
    })
  );
};
