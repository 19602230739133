import { createEvent } from 'effector';
import { debounce } from 'patronum';

export const loginEv = createEvent();
export const logoutEv = createEvent();

export const registerEv = createEvent();

export const setAuthStatusEv = createEvent();

export const setUserInfoEv = createEvent();

export const changeDarkThemeEv = createEvent();

export const changeLanguageEv = createEvent();

export const sendTimersEv = createEvent();

export const setInitDarkStyleEv = createEvent();

export const toggleTabletBarHiddenEv = createEvent();

export const delayedShowTabletBarEv = createEvent();

export const toggleLeftBarSwitchedEv = createEvent();

export const updateFavoritesEv = createEvent();

export const togglePlatformEv = createEvent();

export const getDataPresetsListEv = createEvent();

export const toggleUserUsePresetEv = createEvent();

export const appointPresetToUserEv = createEvent();
export const debouncedAppointEv = debounce({
  source: appointPresetToUserEv,
  timeout: 1000,
});

export const toggleKrishaEv = createEvent();

export const changePresetsAllEv = createEvent();
