import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Divider, Tooltip } from 'antd';
import style from './PublicCategory.module.css';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import {
  CompetitorsIcon,
  HouseholdsIcon,
  IncomeIcon,
  ParkingIcon,
  PedestrianIcon,
  PopulationIcon,
  TjLvlAvgIcon,
} from './icons.jsx';
import MapDemoHexModal from '../MapDemoHexModal/MapDemoHexModal.jsx';
import { $isDarkTheme } from '../../models/authModel/index.js';

function PublicCategory() {
  const chosen_metric = useUnit($activeFilters).chosen_metrics[0];
  const changeActiveFilter = useUnit(changeActiveFilterEv);
  const [expanded, setExpanded] = useState(false);
  const isDark = useUnit($isDarkTheme);
  // FIXME if the template is wrong use indexCards to form items
  // const indexCards = useUnit($indexCards);

  const changeCategory = (value) => {
    if (chosen_metric === value) return;
    changeActiveFilter({
      field: 'chosen_metrics',
      value,
    });
  };

  const templateCategories = [
    {
      title: 'Население',
      icon: <PopulationIcon />,
      info: 'Показатель рассчитывается на основе средней стоимости аренды жилья в выбранной локации. Он предоставляет ориентировочные данные о доходах населения, учитывая уровень расходов на аренду, что позволяет сделать выводы о платежеспособности жителей. Показатель "Средний уровень дохода" помогает бизнесу оценить покупательскую способность клиентов и адаптировать предложения, ассортимент и цены под реальные возможности жителей. Эти данные особенно важны для компаний, предлагающих товары и услуги, ориентированные на разные уровни доходов.',
      index_name: 'population_total',
    },
    {
      title: 'Доход',
      icon: <IncomeIcon />,
      info: 'Показатель рассчитывается на основе средней стоимости аренды жилья в выбранной локации. Он предоставляет ориентировочные данные о доходах населения, учитывая уровень расходов на аренду, что позволяет сделать выводы о платежеспособности жителей. Показатель "Средний уровень дохода" помогает бизнесу оценить покупательскую способность клиентов и адаптировать предложения, ассортимент и цены под реальные возможности жителей. Эти данные особенно важны для компаний, предлагающих товары и услуги, ориентированные на разные уровни доходов.',
      index_name: 'income_avg_range',
    },
    {
      title: 'Количество домохозяйств',
      icon: <HouseholdsIcon />,
      info: 'Показатель рассчитывается на основе средней стоимости аренды жилья в выбранной локации. Он предоставляет ориентировочные данные о доходах населения, учитывая уровень расходов на аренду, что позволяет сделать выводы о платежеспособности жителей. Показатель "Средний уровень дохода" помогает бизнесу оценить покупательскую способность клиентов и адаптировать предложения, ассортимент и цены под реальные возможности жителей. Эти данные особенно важны для компаний, предлагающих товары и услуги, ориентированные на разные уровни доходов.',
      index_name: 'households',
    },
    {
      title: 'Конкуренты и смежный бизнес',
      icon: <CompetitorsIcon />,
      info: 'Показатель рассчитывается на основе средней стоимости аренды жилья в выбранной локации. Он предоставляет ориентировочные данные о доходах населения, учитывая уровень расходов на аренду, что позволяет сделать выводы о платежеспособности жителей. Показатель "Средний уровень дохода" помогает бизнесу оценить покупательскую способность клиентов и адаптировать предложения, ассортимент и цены под реальные возможности жителей. Эти данные особенно важны для компаний, предлагающих товары и услуги, ориентированные на разные уровни доходов.',
      index_name: 'competitors_cnt_B',
    },
    {
      title: 'Пешеходная связность',
      icon: <PedestrianIcon />,
      info: 'Показатель рассчитывается на основе средней стоимости аренды жилья в выбранной локации. Он предоставляет ориентировочные данные о доходах населения, учитывая уровень расходов на аренду, что позволяет сделать выводы о платежеспособности жителей. Показатель "Средний уровень дохода" помогает бизнесу оценить покупательскую способность клиентов и адаптировать предложения, ассортимент и цены под реальные возможности жителей. Эти данные особенно важны для компаний, предлагающих товары и услуги, ориентированные на разные уровни доходов.',
      index_name: 'pedestrian_connect',
    },
    {
      title: 'Пробки',
      icon: <TjLvlAvgIcon />,
      info: 'Показатель рассчитывается на основе средней стоимости аренды жилья в выбранной локации. Он предоставляет ориентировочные данные о доходах населения, учитывая уровень расходов на аренду, что позволяет сделать выводы о платежеспособности жителей. Показатель "Средний уровень дохода" помогает бизнесу оценить покупательскую способность клиентов и адаптировать предложения, ассортимент и цены под реальные возможности жителей. Эти данные особенно важны для компаний, предлагающих товары и услуги, ориентированные на разные уровни доходов.',
      index_name: 'tj_lvl_avg',
    },
    {
      title: 'Парковки. Места',
      icon: <ParkingIcon />,
      info: 'Показатель рассчитывается на основе средней стоимости аренды жилья в выбранной локации. Он предоставляет ориентировочные данные о доходах населения, учитывая уровень расходов на аренду, что позволяет сделать выводы о платежеспособности жителей. Показатель "Средний уровень дохода" помогает бизнесу оценить покупательскую способность клиентов и адаптировать предложения, ассортимент и цены под реальные возможности жителей. Эти данные особенно важны для компаний, предлагающих товары и услуги, ориентированные на разные уровни доходов.',
      index_name: 'parking_place_cnt',
    },
  ];

  return (
    <div
      className={[
        style.public_category_wrapper,
        expanded && style.expanded,
      ].join(' ')}
      style={{
        background: isDark ? 'rgb(30, 35, 40)' : '#fff',
        color: isDark ? '#fff' : '#000',
      }}
    >
      {expanded && (
        <div
          className={style.public_category_title}
          style={{ color: isDark ? '#fff' : '#000' }}
        >
          Параметры
        </div>
      )}
      {templateCategories.map((item, index) => {
        return (
          <Tooltip title={item.title} placement="left">
            <div
              className={[
                style.public_category_item,
                chosen_metric === item.index_name && style.active,
              ].join(' ')}
              key={`category-${item.index_name}-${index}`}
              onClick={() => {
                changeCategory(item.index_name);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  columnGap: 10,
                }}
              >
                {item.icon}
                {expanded && (
                  <div style={{ color: isDark ? '#fff' : '#000' }}>
                    {item.title}
                  </div>
                )}
              </div>
              {expanded && (
                <InfoCircleOutlined className={style.public_category_info} />
              )}
              <div
                className={style.public_category_popup}
                style={{
                  background: isDark ? 'rgb(30, 35, 40)' : '#fff',
                  color: isDark ? '#fff' : '#000',
                  border: `1px solid ${isDark ? '#000' : '#fff'}`,
                }}
              >
                <div className={style.public_category_popup_title}>
                  {item.title}
                </div>
                {item.info}
              </div>
            </div>
          </Tooltip>
        );
      })}
      <Divider style={{ margin: '12px 0' }} />
      <div
        className={style.expand_button}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          <>
            Свернуть <DoubleRightOutlined />
          </>
        ) : (
          <DoubleLeftOutlined />
        )}
      </div>
      <MapDemoHexModal />
    </div>
  );
}

export default PublicCategory;
