import { Button, Card, Form, Input } from 'antd';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { $authStatus, loginEv, loginFx } from '../../models/authModel/index.js';
import { cookies } from '../../api/axiosinstance.js';

export default function Login() {
  const authStatus = useUnit($authStatus);
  const onLogin = useUnit(loginEv);
  const navigate = useNavigate();
  const isLoading = useUnit(loginFx.pending);

  useEffect(() => {
    if (authStatus) {
      const token = cookies.get('access_token');
      if (token) {
        navigate('/lite');
      }
    }
  }, [authStatus]);

  return (
    <div className="content" style={{ height: '100vh' }}>
      <Card className="login-form" id="login-form">
        <Form
          name="loginForm"
          id="loginForm"
          onFinish={onLogin}
          layout="vertical"
        >
          <Form.Item name="email" label="E-mail">
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Пароль">
            <Input.Password allowClear />
          </Form.Item>
          <div className="login-register">
            <Button
              loading={isLoading}
              htmlType="submit"
              form="loginForm"
              type="primary"
              className="login-btn"
            >
              Войти
            </Button>
            <Link to="/register">Зарегистрироваться</Link>
          </div>
        </Form>
      </Card>
    </div>
  );
}
