export const egov_tax_test = {
  0: {
    question: [
      'Планируете ли Вы заниматься предпринимательской деятельностью по какому-либо из следующих направлений?',
      [
        'производство, хранение и оптовая реализация подакцизных товаров',
        'реализация отдельных видов нефтепродуктов - бензина, дизельного топлива и мазута',
        'проведение лотерей',
        'недропользование',
        'сбор и прием стеклопосуды',
        'сбор (заготовка), хранение, переработка и реализация лома и отходов цветных и черных металлов',
        'консультационные услуги',
        'деятельность в области бухгалтерского учета или аудита',
        'финансовая, страховая деятельность и посредническая деятельность страхового брокера и страхового агента',
        'деятельность в области права, юстиции и правосудия',
        'деятельность в рамках финансового лизинга',
        'оказывать услуги по договору ГПХ',
      ],
    ],
    answers: [
      {
        label: 'Да',
        value: 'Да',
      },
      {
        label: 'Нет',
        value: 'Нет',
      },
    ],
  },
  1: {
    question: 'В какой форме Вы планируете вести свой бизнес?',
    answers: [
      {
        label: 'ИП',
        value: 'ИП',
      },
      {
        label: 'ТОО',
        value: 'ТОО',
      },
    ],
  },
  2: {
    question:
      'Сколько работников Вы планируете оформить по трудовому договору? (ИП)',
    answers: [
      {
        label: 'Не планирую',
        value: 'Не планирую',
      },
      {
        label: 'До 30',
        value: 'До 30',
      },
      {
        label: 'До 50',
        value: 'До 50',
      },
      {
        label: 'Свыше 50',
        value: 'Свыше 50',
      },
    ],
  },
  3: {
    question: 'Какой примерный размер дохода Вы планируете получать? (ИП)',
    answers: [
      {
        label: 'До 3 528 МРП в год',
        value: 'До 3 528 МРП в год',
      },
      {
        label: 'До 24 038 МРП за полугодие',
        value: 'До 24 038 МРП за полугодие',
      },
    ],
  },
};
