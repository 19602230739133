import React from 'react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import style from './EgovTaxModes.module.css';
import { egov_tax_modes_info } from '../../data/egov_tax_modes_info.js';

function EgovTaxModes() {
  const navigate = useNavigate();

  const onModeClick = (id) => {
    navigate(`/egov-tax-modes/${id}`);
  };

  const startTest = () => {
    navigate('/egov-tax-modes-test');
  };

  return (
    <div>
      <div className={style.tax_modes_title}>Выбор режима налогообложения</div>
      <div className={style.tax_modes_subtitle}>
        Данный сервис носит исключительно информативный характер и представляет
        информацию обо всех доступных вам режимах налогообложения, оставляя
        принятие решения о выборе режима за Вами.
      </div>
      <Button className={style.tax_modes_button} onClick={startTest}>
        Какой режим выбрать?
      </Button>
      <div className={style.tax_modes_block_title}>Режимы налогооблажения</div>
      <div className={style.tax_modes_wrapper}>
        {egov_tax_modes_info.map((mode, index) => {
          return (
            <div
              className={style.tax_mode}
              key={`cert-${index}`}
              onClick={() => onModeClick(mode.id)}
            >
              <div>
                <div className={style.tax_mode_title}>{mode.title}</div>
              </div>
              <RightOutlined style={{ color: 'white' }} />
            </div>
          );
        })}
      </div>
      <div className={style.tax_modes_note}>
        Если при регистрации бизнеса Вы не выберите специальный налоговый режим,
        то по умолчанию признаетесь выбравшим общеустановленный порядок
        налогообложения.
      </div>
    </div>
  );
}

export default EgovTaxModes;
