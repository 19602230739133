import { sample } from 'effector';
import { condition, spread } from 'patronum';
import { getZeroDataFx } from './effects.js';
import {
  putIndexCardsDataEv,
  putPublicCardsDataEv,
} from '../indexCardsModel/index.js';
import {
  calculateIndexCardsByHexEv,
  changePublicBusinessTypeEv,
  initGradientByPublicEv,
  manageGradientEv,
  putZoom7HexagonsEv,
  recalculatePublicCardsEv,
  resetChosenZoom7HexagonEv,
  resetClickCoordsEv,
  setChosenZoom7HexagonEv,
  setClickCoordsEv,
  setRangeGradientEv,
  setRegularGradientEv,
} from './events.js';
import {
  $chosenZoom7Hexagon,
  $clickCoords,
  $publicBusinessType,
  $zoom7Hexagons,
  $zoom7StartHexagons,
} from './stores.js';
import {
  calculateThresholdsEv,
  putThresholdsEv,
} from '../gradientModel/index.js';
import { $activeFilters } from '../activeFiltersModel/index.js';
import { calculateIndexCards } from './utils.js';
import filterHexagons from '../../counters/hex_counter/hex_counter.js';
import { delayedHideLoaderEv } from '../webSocketModel/index.js';
import {
  $activeLitePreset,
  $isPreset,
  $liteBusiness,
} from '../rbpLiteModel/index.js';

$chosenZoom7Hexagon.reset(resetChosenZoom7HexagonEv);

$publicBusinessType.on(changePublicBusinessTypeEv, (_, payload) => payload);

$clickCoords.reset(resetClickCoordsEv);

sample({
  clock: getZeroDataFx.doneData,
  fn: (clock) => clock.city_total,
  target: putPublicCardsDataEv,
});

sample({
  clock: getZeroDataFx.doneData,
  fn: (clock) => clock.polygons,
  target: putZoom7HexagonsEv,
});

sample({
  clock: putZoom7HexagonsEv,
  fn: (clock) =>
    clock.map((item, index) => {
      return {
        ...item,
        id: index,
        properties: {
          ...item.properties,
          index_main: item.properties.competitors_cnt_B1,
        },
      };
    }),
  target: [$zoom7Hexagons, $zoom7StartHexagons, initGradientByPublicEv],
});

sample({
  clock: initGradientByPublicEv,
  filter: () => window.location.pathname === '/public',
  fn: (clock) => clock,
  target: calculateThresholdsEv,
});

// sample({
//   source: [$zoom7Hexagons, $liteBusiness],
//   clock: $isPreset.updates,
//   filter: (source, clock) => !clock,
//   fn: ([hex, liteBusiness]) => {
//     return hex.map((item) => {
//       return {
//         ...item,
//         properties: {
//           ...item.properties,
//           index_main:
//             item.properties[`competitors_cnt_B${liteBusiness.business}`],
//         },
//       };
//     });
//   },
//   target: [$zoom7Hexagons, calculateThresholdsEv],
// });

sample({
  source: $zoom7Hexagons,
  clock: $isPreset.updates,
  filter: (source, clock) => !clock,
  fn: (source) => source,
  target: calculateThresholdsEv,
});

sample({
  source: $liteBusiness,
  clock: $isPreset.updates,
  filter: (source, clock) => !clock,
  fn: (source) => `competitors_cnt_B${source.business || 1}`,
  target: $publicBusinessType,
});
sample({
  source: $chosenZoom7Hexagon,
  clock: setChosenZoom7HexagonEv,
  fn: (source, clock) => {
    if (source && source.id === clock.id) {
      return null;
    }
    return clock;
  },
  target: [$chosenZoom7Hexagon, calculateIndexCardsByHexEv],
});

sample({
  source: $clickCoords,
  clock: setClickCoordsEv,
  fn: (source, clock) => {
    if (source && source.id === clock.id) {
      return null;
    }
    return clock;
  },
  target: $clickCoords,
});

sample({
  source: [$zoom7StartHexagons, $publicBusinessType, $isPreset],
  clock: $activeFilters.updates,
  filter: ([_, __, isPreset]) =>
    window.location.pathname !== '/tezber' && !isPreset,
  fn: ([hexagons, business_type], clock) => {
    const chosen_metric = clock.chosen_metrics[0];
    const mutatedHexagons = hexagons.map((item) => {
      return {
        ...item,
        properties: {
          ...item.properties,
          index_main:
            chosen_metric === 'competitors_cnt_B'
              ? item.properties[business_type]
              : item.properties[chosen_metric],
        },
      };
    });
    if (clock.gradient.length > 0) {
      return {
        activeFilters: clock,
        hexagons: filterHexagons(mutatedHexagons, clock, [], 'index'),
      };
    }
    return {
      activeFilters: clock,
      hexagons: mutatedHexagons,
    };
  },
  target: manageGradientEv,
});

sample({
  source: [$zoom7Hexagons, $activeFilters],
  clock: $publicBusinessType.updates,
  filter: ([_, activeFilters]) =>
    activeFilters.chosen_metrics[0] === 'competitors_cnt_B',
  fn: ([hexagons, activeFilters], clock) => {
    return {
      activeFilters,
      hexagons: hexagons.map((item) => {
        return {
          ...item,
          properties: {
            ...item.properties,
            index_main: item.properties[clock],
          },
        };
      }),
    };
  },
  target: manageGradientEv,
});

condition({
  source: manageGradientEv,
  if: ({ activeFilters }) => {
    return (
      activeFilters.chosen_metrics[0] === 'income_avg_range' ||
      activeFilters.chosen_metrics[0] === 'rent_price_range'
    );
  },
  then: setRangeGradientEv,
  else: setRegularGradientEv,
});

sample({
  clock: setRegularGradientEv,
  fn: (clock) => {
    return clock.hexagons;
  },
  target: [$zoom7Hexagons, calculateThresholdsEv],
});

sample({
  clock: setRangeGradientEv,
  fn: (clock) => {
    return {
      gradient: [0, 1, 2, 3, 5, 6],
      hexagons: clock.hexagons,
    };
  },
  target: spread({
    targets: {
      gradient: putThresholdsEv,
      hexagons: $zoom7Hexagons,
    },
  }),
});

sample({
  source: [$zoom7Hexagons, $chosenZoom7Hexagon],
  clock: $publicBusinessType.updates,
  fn: ([hexagons, chosen_hex], business_type) => {
    if (chosen_hex) {
      return calculateIndexCards([chosen_hex], business_type);
    }
    return calculateIndexCards(hexagons, business_type);
  },
  target: putPublicCardsDataEv,
});

sample({
  source: $publicBusinessType,
  clock: calculateIndexCardsByHexEv,
  filter: (source, clock) => clock,
  fn: (business_type, clock) => calculateIndexCards([clock], business_type),
  target: putPublicCardsDataEv,
});

sample({
  source: [$zoom7Hexagons, $publicBusinessType],
  clock: calculateIndexCardsByHexEv,
  filter: (source, clock) => !clock,
  fn: ([hexagons, business_type], clock) =>
    calculateIndexCards(hexagons, business_type),
  target: putPublicCardsDataEv,
});

// sample({
//   source: $chosenZoom7Hexagon,
//   clock: $zoom7Hexagons.updates,
//   filter: (source) => source,
//   fn: (source, clock) => source.find((item) => item.id === clock.id),
//   target: $chosenZoom7Hexagon,
// });

sample({
  source: [$zoom7StartHexagons, $publicBusinessType],
  clock: resetChosenZoom7HexagonEv,
  fn: ([hexagons, business_type], _) => {
    return calculateIndexCards(hexagons, business_type);
  },
  target: putPublicCardsDataEv,
});

sample({
  clock: $activeLitePreset.updates,
  fn: (clock) => {
    // return `t${clock.business}pt${clock.sub_business}`;
    return `competitors_cnt_B${clock.sub_business}`;
  },
  target: $publicBusinessType,
});
