import React from 'react';
import { Card, Col, Row } from 'antd';
import MapBlock from '../../components/MapBlock/MapBlock.jsx';
import ProfileModal from '../../components/RbpLiteComponents/ProfileModal/ProfileModal.jsx';
import LiteErrorModal from '../../components/RbpLiteComponents/LiteErrorModal/LiteErrorModal.jsx';

function RbpLitePage() {
  return (
    <>
      <Row gutter={[20, 0]} style={{ height: '100%' }}>
        <Col style={{ width: '100%' }}>
          <Card className="map_card" bodyStyle={{ height: '100%' }}>
            <MapBlock />
          </Card>
        </Col>
      </Row>
      <ProfileModal />
      <LiteErrorModal />
    </>
  );
}

export default RbpLitePage;
