import { createEffect } from 'effector';
import dayjs from 'dayjs';
import { api } from '../../api/axiosinstance.js';

export const sendCreateLitePresetFx = createEffect().use(async (payload) => {
  return (await api(true).post('presets/lite', payload)).data;
});

export const downloadReportFx = createEffect().use(async (payload) => {
  let url;

  api(true, 'blob')
    .get(`reports/lite/${payload.id}`)
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${payload.name}_${dayjs().format(
        'YYYY-MM-DD:HH:mm'
      )} .xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
});

export const renamePresetFx = createEffect().use(async ({ id, data }) => {
  const response = await api(true).put(`presets/${id}`, data);
  if (response.status === 200) {
    return {
      data,
      id,
    };
  }
});

export const deleteLitePresetFx = createEffect().use(async (id) => {
  const payload = {
    ids: Array.isArray(id) ? id : [id],
  };
  const response = await api(true).delete(`presets/`, { data: payload });
  if (response.status === 200) return id;
  return null;
});
