import { createEffect } from 'effector';
import { api } from '../../api/axiosinstance.js';

export const sendRegRequestFx = createEffect().use(async (payload) => {
  return (
    await api(false).post('contact-form-requests/', {
      ...payload,
      source_url: 'rbp.qala-ai.com/main',
      request_type: 'RBP',
    })
  ).data;
});
