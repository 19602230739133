import { useLocation } from 'react-router-dom';
import React from 'react';
import Map from '../Map/Map';
import Zoom10Layer from '../Layers/Zoom10Layer.jsx';
import Zoom9Layer from '../Layers/Zoom9Layer.jsx';
import Zoom8Layer from '../Layers/Zoom8Layer.jsx';
import MapStyleSelector from '../MapStyleSelector/MapStyleSelector.jsx';
import MapControls from '../MapControls/MapControls.jsx';
import MapGradient from '../MapGradient/MapGradient.jsx';
import MapGeoCoder from '../MapGeoCoder/MapGeoCoder.jsx';
import IsochroneModal from '../IsochroneModal/IsochroneModal.jsx';
import IsochroneLayer from '../Layers/IsochroneLayer.jsx';
import MapDrawControls from '../MapDrawControls/MapDrawControls.jsx';
import MapNavControls from '../MapNavControls/MapNavControls.jsx';
import DataPresets from '../DataPresets/DataPresets.jsx';
import PresetLayersManager from '../Layers/PresetLayersManager.jsx';
import OpacitySlider from '../OpacitySlider/OpacitySlider.jsx';
import MapDimensionControl from '../MapDimensionControl/MapDimensionControl.jsx';
import MapPresetItem from '../MapPresetItem/MapPresetItem.jsx';
import LayersController from '../LayersController/LayersController.jsx';
import Zoom7Layer from '../Layers/Zoom7Layer.jsx';
import MapDemoHexModal from '../MapDemoHexModal/MapDemoHexModal.jsx';
import PublicBusinessType from '../PublicBusinessType/PublicBusinessType.jsx';
import PublicCategory from '../PublicCategory/PublicCategory.jsx';
import TezberLayer from '../Layers/TezberLayers/TezberLayer.jsx';
import TezberOurLayer from '../Layers/TezberLayers/TezberOurLayer.jsx';
import LayersSwitch from '../TezberComponents/LayersSwitch/LayersSwitch.jsx';
import LandingIsochroneButton from '../RbpLandingComponents/LandingIsochroneButton.jsx';
import LayersModal from '../TezberComponents/LayersModal/LayersModal.jsx';
import TezberPopulationHex from '../Layers/TezberLayers/TezberPopulationHex.jsx';
import CdekLayer from '../Layers/TezberLayers/CdekLayer.jsx';
import ExpressLayer from '../Layers/TezberLayers/ExpressLayer.jsx';
import JanaPostLayer from '../Layers/TezberLayers/JanaPostLayer.jsx';
import KimigoLayer from '../Layers/TezberLayers/KimigoLayer.jsx';
import OzonLayer from '../Layers/TezberLayers/OzonLayer.jsx';
import TezberDotsPreset from '../Layers/TezberLayers/TezberDotsPreset.jsx';
import WildberriesLayer from '../Layers/TezberLayers/WildberriesLayer.jsx';
import LiteMapWidgetsManager from '../RbpLiteComponents/LiteMapWidgetsManager.jsx';
import RentSuggestions from '../RbpLiteComponents/RentSuggestions/RentSuggestions.jsx';
import LiteHexagons from '../Layers/LiteHexagons.jsx';
import MapFullScreen from '../MapFullScreen/MapFullScreen.jsx';
import MeasureButton from '../TezberComponents/Measure/MeasureButton.jsx';
import MeasureLayer from '../Layers/TezberLayers/MeasureLayer.jsx';
import MeasurePopup from '../TezberComponents/Measure/MeasurePopup.jsx';
import LiteRoadsLayer from '../Layers/LiteRoadsLayer.jsx';
import RoutingButton from '../TezberComponents/RoutingButton/RoutingButton.jsx';
import RoutingLayer from '../Layers/TezberLayers/RoutingLayer.jsx';

function MapBlock() {
  const { pathname } = useLocation();

  let widgets = (
    <>
      <MapGeoCoder />
      {/* <Zoom8Layer /> */}
      <Zoom9Layer />
      {/* <Zoom10Layer /> */}
      <IsochroneLayer />
      {/* <MapControls /> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '60%',
          left: '15px',
          transform: 'translateY(-50%)',
          rowGap: '20px',
        }}
      >
        <MapNavControls />
        <MapDrawControls />
      </div>
      <MapDimensionControl />
      <MapGradient />
      <IsochroneModal />
      {/* <PresetLayersManager /> */}
      <MapPresetItem />
      {/* <LayersController /> */}
    </>
  );

  if (pathname === '/public') {
    widgets = (
      <>
        <Zoom7Layer />
        <MapGradient />
        <PublicBusinessType />
        <PublicCategory />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '20px',
            transform: 'translateY(-50%)',
            rowGap: '20px',
          }}
        >
          <MapNavControls />
          <MapDrawControls />
        </div>
      </>
    );
  }

  if (pathname === '/tezber') {
    widgets = (
      <>
        <TezberLayer />
        <CdekLayer />
        <ExpressLayer />
        <JanaPostLayer />
        <KimigoLayer />
        <OzonLayer />
        <WildberriesLayer />
        {/* <TezberOurLayer /> */}
        <TezberPopulationHex />
        {/* <LayersSwitch /> */}
        <div
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
          }}
        >
          <MapStyleSelector />
        </div>
        <LayersModal />
        <MapGradient />
        <MapFullScreen />
        <MeasureButton />
        <MeasureLayer />
        <MeasurePopup />
        <RoutingButton />
        <RoutingLayer />
      </>
    );
  }

  if (pathname === '/payment') {
    widgets = (
      <>
        <IsochroneLayer />
        <LandingIsochroneButton />
      </>
    );
  }

  if (pathname === '/lite') {
    widgets = (
      <>
        <Zoom7Layer />
        <LiteHexagons />
        <MapGradient />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '20px',
            transform: 'translateY(-50%)',
            rowGap: '20px',
          }}
        >
          <MapNavControls />
          <MapDrawControls />
        </div>
        <LiteMapWidgetsManager />
        <LiteRoadsLayer />
        <LandingIsochroneButton />
      </>
    );
  }

  return (
    <>
      <Map />
      {widgets}
    </>
  );
}

export default MapBlock;
