import React, { useState } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import style from './EgovTaxModes.module.css';
import { egov_tax_test } from '../../data/egov_tax_test.js';

function EgovTaxTest() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [answers, setAnswers] = useState({
    0: undefined,
    1: undefined,
    2: undefined,
    3: undefined,
  });

  const onClose = () => {
    setAnswers({
      0: undefined,
      1: undefined,
      2: undefined,
      3: undefined,
    });
    navigate('/egov-tax-modes');
  };

  const onBack = () => {
    setAnswers({
      ...answers,
      [page]: undefined,
    });
    setPage(page - 1);
  };

  const chooseAnswer = (value) => {
    if (page === 0 && value === 'Да') {
      navigate('/egov-tax-modes');
    } else {
      setAnswers({
        ...answers,
        [page]: value,
      });
    }
  };

  const onResultClick = (id) => {
    navigate(`/egov-tax-modes/${id}`);
  };

  return (
    <div>
      <div className={style.tax_test_header}>
        Какой режим выбрать?
        {page !== 0 ? (
          <LeftOutlined className={style.tax_test_back} onClick={onBack} />
        ) : (
          ''
        )}
        <CloseOutlined className={style.tax_test_close} onClick={onClose} />
      </div>
      <div className={style.tax_test_question}>
        {Array.isArray(egov_tax_test[page].question)
          ? egov_tax_test[page].question.map((item, index) => {
              if (Array.isArray(item)) {
                return (
                  <div key={`question_wrapper-${index}`}>
                    {item.map((elem, elemIndex) => {
                      return (
                        <div
                          key={`question-${elemIndex}`}
                          className={style.tax_mode_desc_list}
                        >
                          <div className={style.tax_mode_desc_marker} />
                          <div className={style.tax_mode_desc_text}>{elem}</div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
              return item;
            })
          : egov_tax_test[page].question}
      </div>
      <div className={style.tax_test_options_wrapper}>
        {egov_tax_test[page].answers.map((item, index) => {
          return (
            <div
              className={[
                style.tax_test_option,
                answers[page] === item.value
                  ? style.tax_test_option_active
                  : '',
              ].join(' ')}
              onClick={() => chooseAnswer(item.value)}
            >
              {item.label}
              {answers[page] === item.value ? (
                <CheckOutlined className={style.tax_test_option_checked} />
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
      {answers[page] !== undefined &&
      Object.values(answers).some((item) => item === undefined) &&
      answers[0] !== 'Да' ? (
        <Button
          className={style.tax_test_next}
          onClick={() => setPage(page + 1)}
        >
          Далее
        </Button>
      ) : (
        ''
      )}
      {Object.values(answers).every((item) => item !== undefined) ||
      answers[0] === 'Да' ? (
        <div className={style.tax_test_result}>
          <div className={style.tax_test_result_title}>Вам подходит</div>
          <div
            className={style.tax_mode}
            style={{
              background: '#252629',
            }}
            onClick={() => onResultClick(2)}
          >
            <div>
              <div className={style.tax_mode_title}>
                Специальный режим с использованием фиксированного вычета
              </div>
            </div>
            <RightOutlined style={{ color: 'white' }} />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default EgovTaxTest;
