import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import style from './EgovCertificates.module.css';
import { egov_cert_info } from '../../data/egov_cert_info.js';

function EgovCertificatesView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const certificate = useMemo(() => {
    return egov_cert_info.find((item) => item.id === Number(id));
  }, [id]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <LeftOutlined
        onClick={goBack}
        style={{ fontSize: '24px', color: 'white' }}
      />
      <div className={style.cert_title} style={{ marginTop: 16 }}>
        {certificate.title}
      </div>
      <div className={style.cert_desc_wrapper}>
        {certificate.desc.map((item, index) => {
          return (
            <div key={`cert-desc-${index}`}>
              <div className={style.cert_desc_title}>{item.title}</div>
              <div className={style.cert_desc_text}>
                {Array.isArray(item.text)
                  ? item.text.map((text_item, text_index) => {
                      if (Array.isArray(text_item)) {
                        return (
                          <>
                            {text_item.map((sub_text_item, sub_text_index) => {
                              return (
                                <div
                                  key={`desc-${index}-text-${text_index}-sub-${sub_text_index}`}
                                  className={style.cert_desc_list}
                                  style={{ marginLeft: '20px' }}
                                >
                                  <div
                                    className={style.cert_desc_marker}
                                    style={{
                                      background: 'transparent',
                                      border: '1px solid white',
                                    }}
                                  />
                                  <div style={{ width: '100%' }}>
                                    {sub_text_item}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      }
                      return (
                        <div
                          key={`desc-${index}-text-${text_index}`}
                          className={style.cert_desc_list}
                        >
                          <div className={style.cert_desc_marker} />
                          <div style={{ width: '100%' }}>{text_item}</div>
                        </div>
                      );
                    })
                  : item.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default EgovCertificatesView;
