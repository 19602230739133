import { useUnit } from 'effector-react';
import { Dropdown } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import style from '../RbpLanding.module.css';
import logo from '../../../assets/RbpLandingImages/Header/header_logo.svg';
import {
  $language,
  changeLanguageEv,
  changeMenuModalEv,
} from '../../../models/rbpLandingModel/index.js';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import useWindowSize from '../../../hooks/useWindowSize.js';
import header_burger from '../../../assets/RbpLandingImages/Header/header_burger.svg';

const languageItems = [
  {
    key: 'en',
    label: 'Eng',
  },
  {
    key: 'ru',
    label: 'Рус',
  },
];

function Header({ offset }) {
  const language = useUnit($language);
  const changeLanguage = useUnit(changeLanguageEv);
  const [width, height] = useWindowSize();
  const changeMenuModal = useUnit(changeMenuModalEv);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const scrollTo = (section) => {
    if (pathname !== '/main') {
      navigate('/main');
    }
    setTimeout(() => {
      document.getElementById(section).scrollIntoView();
    }, 0);
  };

  const onOpen = () => {
    changeMenuModal(true);
  };

  const logoClick = () => {
    if (pathname === '/payment') {
      navigate('/main');
    } else {
      window.open('https://qala-ai.com/', '_blank');
    }
  };

  if (width <= 1200) {
    if (offset > 0) {
      return (
        <>
          <div style={{ height: 70, background: 'rgba(243,240,255,1)' }} />
          <div className={style.header_small_fixed}>
            <img
              src={logo}
              onClick={logoClick}
              style={{ height: 25, cursor: 'pointer' }}
            />
            <img src={header_burger} onClick={onOpen} />
          </div>
        </>
      );
    }
    return (
      <div style={{ background: '#f2efffe0' }}>
        <div className={style.container}>
          <div className={style.header_small_wrapper}>
            <img
              src={logo}
              onClick={logoClick}
              style={{ display: 'block', margin: '0 auto', cursor: 'pointer' }}
            />
            <img
              src={header_burger}
              className={style.header_small_burger}
              onClick={onOpen}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        <img
          src={logo}
          onClick={logoClick}
          style={{ height: 25, cursor: 'pointer' }}
        />
        <div className={style.header_menu}>
          <div
            onClick={() => scrollTo('features')}
            className={style.header_menu_item}
          >
            {rbp_landing_translation[language].menu_item_1}
          </div>
          <div
            onClick={() => scrollTo('how_it_works')}
            className={style.header_menu_item}
          >
            {rbp_landing_translation[language].menu_item_2}
          </div>
          <div
            onClick={() => scrollTo('openmap')}
            className={style.header_menu_item}
          >
            {rbp_landing_translation[language].menu_item_3}
          </div>
          <div
            onClick={() => scrollTo('prices')}
            className={style.header_menu_item}
          >
            {rbp_landing_translation[language].menu_item_4}
          </div>
          <div
            onClick={() => scrollTo('faq')}
            className={style.header_menu_item}
          >
            {rbp_landing_translation[language].menu_item_5}
          </div>
          <div
            onClick={() => scrollTo('contacts')}
            className={style.header_menu_item}
          >
            {rbp_landing_translation[language].menu_item_6}
          </div>
          {/* <Dropdown */}
          {/*  menu={{ */}
          {/*    items: languageItems, */}
          {/*    onClick: ({ key }) => { */}
          {/*      changeLanguage(key); */}
          {/*    }, */}
          {/*  }} */}
          {/* > */}
          {/*  <div className={style.header_menu_item}> */}
          {/*    {language === 'en' ? 'Eng' : 'Рус'} */}
          {/*  </div> */}
          {/* </Dropdown> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
