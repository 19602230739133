import { createStore } from 'effector';
import RBP_LIte_index_help from '../../dictionaries/RBP_Lite_dict.json';

const initCategories = [
  {
    title: RBP_LIte_index_help['9'].name_ru,
    index_name: 'd9',
    info: RBP_LIte_index_help['9'].description_ru,
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['1'].name_ru,
    info: RBP_LIte_index_help['1'].description_ru,
    index_name: 'd1',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['6'].name_ru,
    info: RBP_LIte_index_help['6'].description_ru,
    index_name: 'd6',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['2'].name_ru,
    info: RBP_LIte_index_help['2'].description_ru,
    index_name: 'd2',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['3'].name_ru,
    info: RBP_LIte_index_help['3'].description_ru,
    index_name: 'd3',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['4'].name_ru,
    info: RBP_LIte_index_help['4'].description_ru,
    index_name: 'd4',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['7'].name_ru,
    info: RBP_LIte_index_help['7'].description_ru,
    index_name: 'd7',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['8'].name_ru,
    info: RBP_LIte_index_help['8'].description_ru,
    index_name: 'd8',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['10'].name_ru,
    info: RBP_LIte_index_help['10'].description_ru,
    index_name: 'd10',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['5'].name_ru,
    info: RBP_LIte_index_help['5'].description_ru,
    index_name: 'd5',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
  {
    title: RBP_LIte_index_help['11'].name_ru,
    info: RBP_LIte_index_help['11'].description_ru,
    index_name: 'd11',
    value: 4345,
    avg: 5000,
    total: 10000,
  },
];

export const $isPreset = createStore(window.location.pathname !== '/public');

export const $detailsData = createStore([]);
$detailsData.watch((state) => console.log('$detailsData', state));

export const $profileModal = createStore(false);

export const $currentFavs = createStore([]);
$currentFavs.watch((state) => console.log('$currentFavs', state));

export const $currentFavDetails = createStore([]);
$currentFavDetails.watch((state) => console.log('$currentFavDetails', state));

export const $chartFilters = createStore([]);
$chartFilters.watch((state) => console.log('$chartFilters', state));

export const $liteHexagons = createStore([]);
$liteHexagons.watch((state) => console.log('$liteHexagons', state));

export const $liteStartHexagons = createStore([]);
$liteStartHexagons.watch((state) => console.log('$liteStartHexagons', state));

export const $isochroneTotalData = createStore(null);
$isochroneTotalData.watch((state) => console.log('$isochroneTotalData', state));

export const $chosenLiteHexagon = createStore(null);
$chosenLiteHexagon.watch((state) => console.log('$chosenLiteHexagon', state));

export const $liteAggregates = createStore(null);
$liteAggregates.watch((state) => console.log('$liteAggregates', state));

export const $d9Table = createStore(null);
$d9Table.watch((state) => console.log('$d9Table', state));

export const $liteCategories = createStore(initCategories);
$liteCategories.watch((state) => console.log('$liteCategories', state));

export const $krishaObjects = createStore([]);
$krishaObjects.watch((state) => console.log('$krishaObjects', state));

export const $krishaMarkers = createStore(null);
$krishaMarkers.watch((state) => console.log('$krishaMarkers', state));

export const $liteBusiness = createStore({
  business: undefined,
  sub_business: undefined,
});
$liteBusiness.watch((state) => console.log('$liteBusiness', state));

export const $activeLitePreset = createStore(null);
$activeLitePreset.watch((state) => console.log('$activeLitePreset', state));

export const $roadsData = createStore([]);
$roadsData.watch((state) => console.log('$roadsData', state));

export const $roadsStartData = createStore([]);
$roadsData.watch((state) => console.log('$roadsStartData', state));

export const $showRoads = createStore(false);
$showRoads.watch((state) => console.log('$showRoads', state));

export const $liteErrorModal = createStore(false);
