import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Radio, Table, TreeSelect } from 'antd';
import { useUnit } from 'effector-react';
import Icon, {
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  $activeLitePreset,
  $profileModal,
  activatePresetEv,
  deleteLitePresetEv,
  downloadReportEv,
  renamePresetEv,
  sendPaymentUserEv,
  setActivePresetEv,
  toggleProfileModalEv,
} from '../../../models/rbpLiteModel/index.js';
import style from './ProfileModal.module.css';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import {
  $chosenPoint,
  $language,
} from '../../../models/rbpLandingModel/index.js';
import { flyTo } from '../../../utils/mapbox-utils.js';
import { $isDarkTheme, $userInfo } from '../../../models/authModel/index.js';
import payment_business_types from '../../../data/payment_business_types.json';
import EditableLabel from '../../EditableLabel/EditableLabel.jsx';

function MarkSvg() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5C9 9.70435 9.31607 8.94129 9.87868 8.37868C10.4413 7.81607 11.2044 7.5 12 7.5C12.7956 7.5 13.5587 7.81607 14.1213 8.37868C14.6839 8.94129 15 9.70435 15 10.5Z"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 10.5C19.5 17.642 12 21.75 12 21.75C12 21.75 4.5 17.642 4.5 10.5C4.5 8.51088 5.29018 6.60322 6.6967 5.1967C8.10322 3.79018 10.0109 3 12 3C13.9891 3 15.8968 3.79018 17.3033 5.1967C18.7098 6.60322 19.5 8.51088 19.5 10.5Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function MarkIcon(props) {
  return <Icon component={MarkSvg} {...props} />;
}

function ReportSvg() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 14.25V11.625C19.5 10.7299 19.1444 9.87145 18.5115 9.23851C17.8786 8.60558 17.0201 8.25 16.125 8.25H14.625C14.3266 8.25 14.0405 8.13147 13.8295 7.9205C13.6185 7.70952 13.5 7.42337 13.5 7.125V5.625C13.5 4.72989 13.1444 3.87145 12.5115 3.23851C11.8785 2.60558 11.0201 2.25 10.125 2.25H8.25M9 16.5V17.25M12 14.25V17.25M15 12V17.25M10.5 2.25H5.625C5.004 2.25 4.5 2.754 4.5 3.375V20.625C4.5 21.246 5.004 21.75 5.625 21.75H18.375C18.996 21.75 19.5 21.246 19.5 20.625V11.25C19.5 8.86305 18.5518 6.57387 16.864 4.88604C15.1761 3.19821 12.8869 2.25 10.5 2.25Z"
        stroke="#A5A5A5"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function ReportIcon(props) {
  return <Icon component={ReportSvg} {...props} />;
}

const packages = [
  {
    title: {
      ru: 'Одна локация',
      en: 'One location',
    },
    // price: 14500,
    price: 'XXX XXX',
    name: 'location_1',
  },
  {
    title: {
      ru: 'Три локации',
      en: 'Three locations',
    },
    // price: 36000,
    price: 'XXX XXX',
    discount: 17,
    name: 'location_3',
  },
  {
    title: {
      ru: 'Пять локаций',
      en: 'Five locations',
    },
    // price: 50000,
    price: 'XXX XXX',
    discount: 31,
    name: 'location_5',
  },
  {
    title: {
      ru: 'Десять локаций',
      en: 'Ten locations',
    },
    // price: 90000,
    price: 'XXX XXX',
    discount: 38,
    name: 'location_10',
  },
];

function ProfileModal() {
  const open = useUnit($profileModal);
  const toggleProfileModal = useUnit(toggleProfileModalEv);
  const [form] = Form.useForm();
  const language = useUnit($language);
  const [chosenPackage, setChosenPackage] = useState(1);
  const [reportsExpanded, setReportsExpanded] = useState(false);
  const chosenPoint = useUnit($chosenPoint);
  const userInfo = useUnit($userInfo);
  const activatePreset = useUnit(activatePresetEv);
  const setActivePreset = useUnit(setActivePresetEv);
  const activeLitePreset = useUnit($activeLitePreset);
  const downloadReport = useUnit(downloadReportEv);
  const renamePreset = useUnit(renamePresetEv);
  const isDark = useUnit($isDarkTheme);
  const deleteLitePreset = useUnit(deleteLitePresetEv);
  const sendPaymentUser = useUnit(sendPaymentUserEv);

  const onClose = () => {
    toggleProfileModal(false);
    setReportsExpanded(false);
  };

  const zoomToClick = () => {
    flyTo([chosenPoint._lngLat.lng, chosenPoint._lngLat.lat], 13);
  };

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'profile_report_name',
      render: (name, record) => {
        const onSave = (value) => {
          renamePreset({
            id: record.id,
            value,
          });
        };
        return <EditableLabel text={record.name} onSave={onSave} />;
      },
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      key: 'profile_report_created_at',
    },
    {
      title: 'Доступно до',
      dataIndex: 'expires_at',
      key: 'profile_report_expires_at',
    },
    {
      title: 'Действия',
      key: 'profile_report_actions',
      render: (_, record) => {
        const isActive =
          activeLitePreset.location_id === record.location_id &&
          activeLitePreset.business === record.isochrones.business &&
          activeLitePreset.sub_business === record.isochrones.sub_business &&
          activeLitePreset.id === record.id;
        return (
          <div style={{ display: 'flex' }}>
            <MarkIcon
              style={{
                cursor: isActive ? 'not-allowed' : 'pointer',
                color: isActive ? '#2D9CDB' : '#A5A5A5',
              }}
              onClick={
                !isActive
                  ? () => {
                      activatePreset({
                        location_id: record.location_id,
                        business: record.business,
                        sub_business: record.sub_business,
                      });
                      setActivePreset({
                        ...record.isochrones,
                        id: record.id,
                      });
                      onClose();
                    }
                  : () => {}
              }
            />
            <ReportIcon
              onClick={() => {
                downloadReport(record);
              }}
            />
            {userInfo?.perms?.includes('admin') && (
              <DeleteOutlined
                style={{
                  fontSize: 24,
                  color: '#A5A5A5',
                  cursor: isActive ? 'not-allowed' : 'pointer',
                }}
                onClick={
                  isActive ? () => {} : () => deleteLitePreset(record.id)
                }
              />
            )}
          </div>
        );
      },
    },
  ];

  const onFinish = () => {
    sendPaymentUser(packages[chosenPackage - 1].name);
  };

  useEffect(() => {
    if (userInfo.presets_all === userInfo.presets_used) {
      setReportsExpanded(true);
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={600}
      title="Личный кабинет"
      closable={false}
    >
      <div className={style.profile_form_wrapper}>
        <div
          className={style.profile_reports_label}
          style={{ color: isDark ? '#fff' : '#717171' }}
        >
          Отчетов осталось:
        </div>
        <div className={style.profile_reports_count}>
          <div
            className={style.profile_reports_current}
            style={{ color: 'red' }}
          >
            {userInfo.presets_all - userInfo.presets_used}
          </div>
          /{userInfo.presets_all}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          <Button
            className={style.profile_reports_button}
            icon={reportsExpanded ? <UpOutlined /> : <DownOutlined />}
            iconPosition="end"
            onClick={() => setReportsExpanded(!reportsExpanded)}
          >
            Купить отчеты
          </Button>
        </div>
        {reportsExpanded ? (
          <>
            {/* <Button */}
            {/*  type="primary" */}
            {/*  className={style.payments_address_button} */}
            {/*  disabled={!chosenPoint} */}
            {/*  onClick={zoomToClick} */}
            {/* > */}
            {/*  Проверить адрес */}
            {/* </Button> */}
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <div>
                <label
                  className={style.payments_form_label}
                  style={{ color: isDark ? '#fff' : '#111827' }}
                >
                  {rbp_landing_translation[language].payments_form_package}:
                </label>
                <div className={style.packages_wrapper}>
                  {packages.map((item, index) => (
                    <div
                      key={`package_${index}`}
                      className={[
                        style.payments_form_package,
                        chosenPackage === index + 1 ? style.package_active : '',
                      ].join(' ')}
                      onClick={() => setChosenPackage(index + 1)}
                      style={{
                        background: isDark ? '#1E2328' : '#fff',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Radio
                          value={index + 1}
                          checked={chosenPackage === index + 1}
                        />
                        <div
                          className={style.payments_package_title}
                          style={{ color: isDark ? 'white' : '#111827' }}
                        >
                          {item.title[language]}
                        </div>
                        {item.discount && (
                          <div className={style.payments_package_discount}>
                            -{item.discount}%
                          </div>
                        )}
                      </div>
                      <div
                        className={style.payments_package_price}
                        style={{
                          color:
                            chosenPackage === index + 1
                              ? isDark
                                ? 'white'
                                : '#11023B'
                              : '#828282',
                        }}
                      >
                        {item.price}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Button
                className={style.payments_form_button}
                onClick={form.submit}
              >
                {rbp_landing_translation[language].payments_form_button_lite}
              </Button>
            </Form>
          </>
        ) : (
          ''
        )}
      </div>
      <Table
        columns={columns}
        dataSource={
          Object.keys(userInfo).length && userInfo.presets.rbp_lite.length > 0
            ? userInfo.presets.rbp_lite.map((item) => {
                const uuid = `t${item.isochrones.business}pt${item.isochrones.sub_business}`;
                const category = payment_business_types.find((type) => {
                  return type.subcategories.find((elem) => elem.uuid === uuid);
                });
                return {
                  ...item,
                  key: item.isochrones.location_id,
                  location_id: item.isochrones.location_id,
                  // name: `${category.type}, ${
                  //   category.subcategories.find(
                  //     (subtype) => subtype.uuid === uuid
                  //   ).subtype
                  // }`,
                };
              })
            : []
        }
        pagination={false}
      />
      <Button
        shape="circle"
        className={[style.close_button, style.close_button_desktop].join(' ')}
        icon={<CloseOutlined />}
        onClick={onClose}
      />
    </Drawer>
  );
}

export default ProfileModal;
