export const egov_cert_info = [
  {
    title: 'Сертификат о происхождении товара формы «СТ-1»',
    id: 1,
    countries: ['Страны СНГ', 'Грузия', 'Узбекистан, Туркменистан'],
    desc: [
      {
        title: 'Описание сертификата',
        text: 'Сертификат СТ-1 подтверждает происхождение товаров и необходим при экспорте в страны СНГ для предоставления таможенных льгот, например, освобождения от таможенных пошлин. Этот документ не требуется для товаров, перемещаемых между Россией, Казахстаном и Беларусью благодаря упрощенной системе оформления.',
      },
      {
        title: 'Процесс оформления',
        text: 'Документы, предоставленные заявителем, анализируются для подтверждения происхождения товара. Сертификаты могут быть оформлены как до, так и после отгрузки товара. Оформление проводится с использованием защищенных бланков, при этом экспортеру выдаются оригинал и копия.',
      },
      {
        title: 'Срок действия',
        text: 'Срок действия сертификата составляет 12 месяцев с даты его выдачи.',
      },
      {
        title: 'Критерии происхождения',
        text: 'Товары признаются происходящими из Казахстана, если они полностью получены или произведены в стране. Примеры включают добычу полезных ископаемых, выращенную продукцию растительного происхождения, и животных, родившихся и выращенных в Казахстане.',
      },
      {
        title: 'Процедура выдачи',
        text: 'Сертификат выдается в течение одного рабочего дня после регистрации заявки, если предоставлены все требуемые документы. В случае отказа, выносится мотивированное решение.',
      },
    ],
  },
  {
    title: 'Сертификат о происхождении товара формы «СТ-2»',
    id: 2,
    countries: ['Сербия'],
    desc: [
      {
        title: 'Описание сертификата',
        text: 'Сертификат СТ-2 оформляется для подтверждения происхождения реэкспортируемых товаров, в основном в Республику Сербию, включая товары, ввезенные в Казахстан из стран ЕАЭС. Сертификат может быть оформлен как до, так и после отгрузки товара.',
      },
      {
        title: 'Срок действия',
        text: 'Срок действия сертификата составляет 12 месяцев с даты его выдачи.',
      },
      {
        title: 'Критерии происхождения',
        text: 'Товары считаются происходящими из Казахстана, если они полностью произведены или добыты в стране. Это включает добычу полезных ископаемых, растениеводство, животноводство и переработку.',
      },
      {
        title: 'Процедура выдачи',
        text: 'Оформление сертификата происходит на основании электронных документов через систему "Атамекен". Сертификат выдается в течение одного рабочего дня после регистрации заявки.',
      },
      {
        title: 'Полезность сертификатов',
        text: 'Сертификаты СТ-2 обеспечивают тарифные преференции, включая снижение или освобождение от таможенных пошлин, что предоставляет казахстанским экспортерам конкурентные преимущества на международном уровне.',
      },
    ],
  },
  {
    title: 'Сертификат о происхождении товара формы «СТ-3»',
    id: 3,
    countries: ['Иран'],
    desc: [
      {
        title: 'Описание сертификата',
        text: 'Сертификат происхождения формы СТ-3 предназначен для подтверждения казахстанского происхождения товаров при их экспорте. Этот документ важен для компаний, ведущих внешнеэкономическую деятельность, поскольку он помогает получать тарифные преференции или льготы при экспорте в различные страны.',
      },
      {
        title: 'Процесс оформления',
        text: [
          'Подача заявки: Экспортер должен подать заявку на получение сертификата в электронной форме через уполномоченные органы или через систему "Атамекен". В заявке необходимо указать полную информацию о товаре и предоставить все требуемые документы.',
          'Проверка документов: Предоставленные документы подвергаются тщательной проверке на соответствие заявленным критериям происхождения. Это может включать проверку производственных мощностей, аудит на местах и экспертизу материалов.',
          'Выдача сертификата: После подтверждения соответствия товара требованиям происхождения, сертификат формы СТ-3 выдается на определенный срок. Если документы не соответствуют требованиям, может быть вынесено мотивированное решение об отказе.',
        ],
      },
      {
        title: 'Срок действия',
        text: 'Сертификат действителен в течение 12 месяцев с даты выдачи. Этот период позволяет использовать его для множества отгрузок экспортируемых товаров.',
      },
      {
        title: 'Критерии происхождения',
        text: 'Товары должны удовлетворять определенным критериям, чтобы считаться происходящими из Казахстана: Произведены полностью на территории Казахстана, изготовлены из местного сырья или материалов, значительная доля добавленной стоимости создана в Казахстане.',
      },
      {
        title: 'Значение для экспортеров',
        text: 'Сертификат СТ-3 позволяет экспортерам участвовать в международной торговле на более выгодных условиях, снижая таможенные пошлины или полностью освобождая от них. Это способствует увеличению конкурентоспособности товаров на международных рынках.',
      },
    ],
  },
  {
    title: 'Сертификат о происхождении товара формы «EAV»',
    id: 4,
    countries: ['Вьетнам'],
    desc: [
      {
        title: 'Описание сертификата',
        text: 'Сертификат EAV предназначен для подтверждения происхождения товаров при их экспорте, особенно в страны, с которыми у Казахстана заключены международные соглашения о торговле. Этот сертификат помогает получать таможенные льготы или тарифные преференции.',
      },
      {
        title: 'Процесс оформления',
        text: 'Заявление на получение сертификата подается в электронной форме через информационную систему "Атамекен". Необходимо предоставить документы, строго соответствующие установленным правилам и нормам.',
      },
      {
        title: 'Критерии происхождения',
        text: 'Товар должен полностью производиться в Казахстане или значительно перерабатываться на территории страны. Важна значительная доля созданной добавленной стоимости в производственном процессе в Казахстане.',
      },
      {
        title: 'Срок действия',
        text: 'Сертификат EAV действителен в течение 12 месяцев с момента выдачи, что позволяет использовать его для нескольких партий товаров.',
      },
      {
        title: 'Значение для экспортеров',
        text: 'Сертификат позволяет участвовать в международной торговле на более выгодных условиях, снижая таможенные пошлины или полностью освобождая от них, что укрепляет конкурентные позиции на международных рынках.',
      },
    ],
  },
  {
    title: 'Сертификат о происхождении товара формы «A»',
    id: 5,
    countries: [
      'Страны ЕС',
      'США',
      'Канада',
      'Япония',
      'Турция',
      'Австралия',
      'Новая Зеландия',
      'Лихтенштейн и др.страны, пользующиеся GSP',
    ],
    desc: [
      {
        title: 'Описание сертификата',
        text: 'Сертификат формы А используется для подтверждения происхождения товаров из Казахстана при экспорте в страны, участвующие в программе Генеральной системы преференций (GSP). Это включает страны как Европейский Союз, США, Канада и Япония. Сертификат позволяет экспортерам пользоваться тарифными преференциями, такими как сниженные таможенные ставки или освобождение от них.',
      },
      {
        title: 'Процесс оформления',
        text: 'Сертификат оформляется на каждую отдельную поставку товара. Может быть выдан как до, так и после отгрузки товара. Оформление регулируется международными нормативными документами и требует строгого соблюдения предписанных критериев.',
      },
      {
        title: 'Срок действия',
        text: 'Срок действия сертификата составляет 10 месяцев с момента выдачи, что позволяет использовать его для регулярных поставок товаров в течение почти всего года.',
      },
      {
        title: 'Критерии происхождения',
        text: 'Для получения сертификата, товары должны удовлетворять следующим условиям: полностью получены или произведены в Казахстане, включая широкий спектр товаров от минеральных ресурсов до сельскохозяйственной продукции и промышленных изделий.',
      },
      {
        title: 'Значение для экспортеров',
        text: 'Этот сертификат важен для казахстанских экспортеров, так как облегчает доступ на рынки стран с развитой экономикой и способствует расширению международной торговли.',
      },
    ],
  },
  {
    title: 'Система REX',
    id: 6,
    countries: ['Норвегия', 'Швейцария'],
    desc: [
      {
        title: 'Описание сертификата',
        text: [
          'В соответствии с Регламентом Европейского союза № 2015/2447 от 24 ноября 2015 года в рамках Генеральной системы преференций (ГСП) Европейским союзом, Швейцарией и Норвегией внедрена Система экспортеров Европейского Союза (Система REX).',
          'С 1 января 2014 года Казахстан в список пользователей преференций ГСП Европейского союза не относится и может получать преференции (снижение ставок ввозных таможенных пошлин) только при экспорте продукции в Норвегию и Швейцарию.',
          'С 1 мая 2021 года для получения преференций при экспорте продукции в Швейцарию и Норвегию сертификаты о происхождении товаров формы «А» не принимаются.',
          'Для этих целей необходимо получить регистрационный номер в Системе REX (Registered exporters system), для самостоятельного заявления о происхождении товара в счете-фактуре или в любом другом коммерческом документе.',
          'Стоит отметить, что экспорт товаров стоимостью до 6 000 евро для получения преференций не требует наличия регистрации в Системе REX и может подтверждаться декларацией о происхождении товара (заявление о происхождении товара, сделанное в счете-фактуре или в любом другом коммерческом документе).',
          'Для экспорта товаров в страны Европейского союза регистрация в данной системе не требуется.',
        ],
      },
      {
        title: 'Процесс оформления',
        text: [
          'Законом РК «О Национальной палате предпринимателей Республики Казахстан» НПП «Атамекен» наделена компетенцией по регистрации экспортеров в Системе REX.',
          'Порядок регистрации в Системе REX определен приказом Министра торговли и интеграции Республики Казахстан от 31 марта 2021 года № 244-НҚ.',
          'Для получения регистрационного номера экспортера (REX-номера) необходимо:',
          [
            'обратиться в Региональную палату предпринимателей в 17 областях, городах республиканского значения и столице;',
            'представить заявление на получение регистрационного номера по форме согласно приложению 1 к приказу МТИ РК от 31 марта 2021 года № 244-НҚ и копии документов согласно Перечню, утвержденному приказом МИР РК от 8 июля 2014 года № 257 .',
          ],
          'Срок регистрации – не позднее 5 (пяти) рабочих дней, следующих за днем регистрации заявки с полным пакетом документов.',
          'Регистрация в системе REX осуществляется единожды, на неограниченный срок, и после получения REX-номера, который будет указываться экспортером на инвойсе, не потребуется получать сертификат на каждую партию товара.',
        ],
      },
      {
        title: 'Стоимость оказания услуги',
        text: [
          'Согласно решения Президиума Национальной палаты предпринимателей Республики Казахстан «Атамекен» стоимость услуги по регистрации экспортеров в информационных системах стран ввоза товара или внесение изменений в регистрационные данные экспортеров (в части перечня товаров) составляет 4 МРП.',
          'При этом, для субъектов предпринимательства, не имеющих задолженности по оплате обязательным членским взносам, предоставляется дисконт 50% и стоимость составляет 2 МРП.',
        ],
      },
    ],
  },
  {
    title:
      'Непреференциальный сертификат о происходении товара общей формы «Оригинал»',
    id: 7,
    countries: [
      'Третьи страны, с которыми не заключены международные соглашения',
    ],
    desc: [
      {
        title: 'Процесс оформления',
        text: 'Оформление сертификата о происхождении товара общей формы «Оригинал» регламентируется приказом Министра торговли и интеграции Республики Казахстан от 13 июля 2021 года № 454-НҚ «Об утверждении Правил по определению страны происхождения товара, статуса товара Евразийского экономического союза или иностранного товара, выдаче сертификата о происхождении товара и отмене его действия, установлении форм сертификата по определению страны происхождения товара»',
      },
      {
        title:
          'Сертификат о происхождении товара общей формы «Оригинал» оформляется для нетарифного регулирования, в том числе:',
        text: [
          'Сертификат о происхождении товара формы «Оригинал» оформляется, на каждую отдельную поставку товара, которая осуществляется одним или несколькими транспортными средствами при экспорте товара с территории Республики Казахстан.',
          'Сертификат о происхождении товара формы «Оригинал» может быть оформлен и после отгрузки товара.',
          'Сертификат о происхождении товара формы «Оригинал» может быть оформлен на реэкспортируемый товар, т.е. это когда товар завозится на территорию Казахстана с территории любого государства и далее экспортируется с территории Казахстана.',
        ],
      },
      {
        title: 'Срок действия сертификата',
        text: 'Срок действия сертификата о происхождении товара формы «Оригинал» составляет 12 месяцев с даты выдачи.',
      },
    ],
  },
];
