import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import style from './EgovCertificates.module.css';
import { egov_cert_info } from '../../data/egov_cert_info.js';

function EgovCertificates() {
  const navigate = useNavigate();

  const onCertClick = (id) => {
    navigate(`/egov-certificates/${id}`);
  };

  return (
    <div>
      <div className={style.cert_title}>Консультации по сертификатам</div>
      <div className={style.cert_subtitle}>
        Сертификация — это процедура, в ходе которой уполномоченная организация
        подтверждает соответствие продукции, работ, услуг и процессов
        установленным нормам и требованиям
      </div>
      <div className={style.cert_types_title}>Типы сертификатов</div>
      <div className={style.cert_types_wrapper}>
        {egov_cert_info.map((item, index) => {
          return (
            <div
              className={style.cert_type}
              key={`cert-${index}`}
              onClick={() => onCertClick(item.id)}
            >
              <div>
                <div className={style.cert_type_title}>{item.title}</div>
                <div className={style.cert_type_countries}>
                  {item.countries.join(', ')}
                </div>
              </div>
              <RightOutlined style={{ color: 'white' }} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default EgovCertificates;
