import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { egov_tax_modes_info } from '../../data/egov_tax_modes_info.js';
import style from './EgovTaxModes.module.css';

const tabs = [
  {
    label: 'Условия',
    value: 'conditions',
  },
  {
    label: 'Плюсы',
    value: 'pros',
  },
  {
    label: 'Минусы',
    value: 'cons',
  },
  {
    label: 'Разрешенные виды деятельности',
    value: 'allowed_activities',
  },
];

function EgovTaxModeView() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState('conditions');

  const mode = useMemo(() => {
    return egov_tax_modes_info.find((item) => item.id === Number(id));
  }, [id]);

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <LeftOutlined
        onClick={goBack}
        style={{ fontSize: '24px', color: 'white' }}
      />
      <div className={style.tax_modes_title} style={{ marginTop: 16 }}>
        {mode.title}
      </div>
      <div className={style.tax_modes_subtitle}>{mode.subtitle}</div>
      <div style={{ overflow: 'scroll', marginTop: 24 }}>
        <div className={style.tax_mode_tabs_wrapper}>
          {tabs.map((item) => {
            if (mode[item.value].length > 0) {
              return (
                <div
                  key={`tab-${item.value}`}
                  className={[
                    style.tax_mode_tab,
                    tab === item.value ? style.tab_active : '',
                  ].join(' ')}
                  onClick={() => setTab(item.value)}
                >
                  {item.label}
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className={style.tax_mode_desc_wrapper}>
        {mode[tab].map((item, index) => {
          return (
            <div
              key={`mode-desc-${index}`}
              className={style.tax_mode_desc_list}
            >
              <div className={style.tax_mode_desc_marker} />
              <div className={style.tax_mode_desc_text}>{item}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default EgovTaxModeView;
