import { createBrowserRouter } from 'react-router-dom';
import App from '../App.jsx';
import MainPage from '../pages/MainPage/MainPage.jsx';
import Login from '../pages/Login/Login.jsx';
import Register from '../pages/Register/Register.jsx';
import MainPageRestyle from '../pages/MainPage/MainPageRestyle.jsx';
import UserList from '../pages/UserList/UserList.jsx';
import UserView from '../pages/UserView/UserView.jsx';
import DemoMainPage from '../pages/MainPage/DemoMainPage.jsx';
import Chat from '../pages/AlmatyBusinessChats/Chat/Chat.jsx';
import ChatV2 from '../pages/AlmatyBusinessChats/ChatV2/Chat.jsx';
import Banking from '../pages/AlmatyBusinessChats/Banking/Chat.jsx';
import Crowdfunding from '../pages/AlmatyBusinessChats/Crowdfunding/Chat.jsx';
import Elnet from '../pages/AlmatyBusinessChats/Elnet/Chat.jsx';
import Gassup from '../pages/AlmatyBusinessChats/Gassup/Chat.jsx';
import Heatsys from '../pages/AlmatyBusinessChats/Heatsys/Chat.jsx';
import Landrules from '../pages/AlmatyBusinessChats/Landrules/Chat.jsx';
import Realestate from '../pages/AlmatyBusinessChats/Realestate/Chat.jsx';
import Watersup from '../pages/AlmatyBusinessChats/Watersup/Chat.jsx';
import RbpLanding from '../pages/RbpLanding/RbpLanding.jsx';
import Tezber from '../pages/Tezber/Tezber.jsx';
import MainLanding from '../pages/RbpLanding/subPages/MainLanding.jsx';
import PaymentPage from '../pages/RbpLanding/subPages/PaymentPage.jsx';
import RequestsList from '../pages/RequestsList/RequestsList.jsx';
import RbpLitePage from '../pages/MainPage/RbpLitePage.jsx';
import BusinessProtection from '../pages/AlmatyBusinessChats/BusinessProtection/Chat.jsx';
import BusinessPlan from '../pages/AlmatyBusinessChats/BusinessPlan/Chat.jsx';
import Trademark from '../pages/AlmatyBusinessChats/Trademark/Chat.jsx';
import EgovChat from '../pages/EgovChats/Chat/Chat.jsx';
import EgovChatV2 from '../pages/EgovChats/ChatV2/Chat.jsx';
import EgovBanking from '../pages/EgovChats/Banking/Chat.jsx';
import EgovCrowdfunding from '../pages/EgovChats/Crowdfunding/Chat.jsx';
import EgovElnet from '../pages/EgovChats/Elnet/Chat.jsx';
import EgovGassup from '../pages/EgovChats/Gassup/Chat.jsx';
import EgovHeatsys from '../pages/EgovChats/Heatsys/Chat.jsx';
import EgovLandrules from '../pages/EgovChats/Landrules/Chat.jsx';
import EgovRealestate from '../pages/EgovChats/Realestate/Chat.jsx';
import EgovWatersup from '../pages/EgovChats/Watersup/Chat.jsx';
import EgovBusinessProtection from '../pages/EgovChats/BusinessProtection/Chat.jsx';
import EgovBusinessPlan from '../pages/EgovChats/BusinessPlan/Chat.jsx';
import EgovTrademark from '../pages/EgovChats/Trademark/Chat.jsx';
import { egov_chats_info } from '../data/egov_chats_info.js';
import EgovChatPreset from '../pages/EgovChats/EgovChatPreset/EgovChatPreset.jsx';
import { almaty_business_chats_info } from '../data/almaty_business_chats_info.js';
import AlmatyBusinessChatPreset from '../pages/AlmatyBusinessChats/AlmatyBusinessChatPreset/AlmatyBusinessChatPreset.jsx';
import EgovCertificates from '../pages/EgovCertificates/EgovCertificates.jsx';
import EgovCertificatesView from '../pages/EgovCertificates/EgovCertificatesView.jsx';
import EgovTaxModes from '../pages/EgovTaxModes/EgovTaxModes.jsx';
import EgovTaxModeView from '../pages/EgovTaxModes/EgovTaxModeView.jsx';
import EgovTaxTest from '../pages/EgovTaxModes/EgovTaxTest.jsx';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'rbp', element: <MainPageRestyle /> },
      { path: 'user-list', element: <UserList /> },
      { path: 'user/:id', element: <UserView /> },
      { path: 'public', element: <DemoMainPage /> },
      { path: 'lite', element: <RbpLitePage /> },
      { path: 'tezber', element: <Tezber /> },
      { path: 'requests-list', element: <RequestsList /> },

      // FIXME Egov chats routes
      // { path: 'egov-chat', element: <EgovChat /> },
      // { path: 'egov-chat-v2', element: <EgovChatV2 /> },
      // { path: 'egov-banking', element: <EgovBanking /> },
      // { path: 'egov-crowdfunding', element: <EgovCrowdfunding /> },
      // { path: 'egov-realestate', element: <EgovRealestate /> },
      // { path: 'egov-elnet', element: <EgovElnet /> },
      // { path: 'egov-heatsys', element: <EgovHeatsys /> },
      // { path: 'egov-gassup', element: <EgovGassup /> },
      // { path: 'egov-watersup', element: <EgovWatersup /> },
      // { path: 'egov-landrules', element: <EgovLandrules /> },
      // { path: 'egov-business-protection', element: <EgovBusinessProtection /> },
      // { path: 'egov-business-plan', element: <EgovBusinessPlan /> },
      // { path: 'egov-trademark', element: <EgovTrademark /> },
      { path: 'egov-certificates', element: <EgovCertificates /> },
      { path: `egov-certificates/:id`, element: <EgovCertificatesView /> },
      { path: 'egov-tax-modes', element: <EgovTaxModes /> },
      { path: 'egov-tax-modes/:id', element: <EgovTaxModeView /> },
      { path: 'egov-tax-modes-test', element: <EgovTaxTest /> },
      ...egov_chats_info.map((item) => {
        return {
          path: item.route,
          element: (
            <EgovChatPreset
              apiUrl={item.apiUrl}
              promptMessage={item.promptMessage}
              preparedMessages={item.preparedMessages}
            />
          ),
        };
      }),

      // FIXME Almaty Business Chats
      // { path: 'chat', element: <Chat /> },
      // { path: 'chat-v2', element: <ChatV2 /> },
      // { path: 'banking', element: <Banking /> },
      // { path: 'crowdfunding', element: <Crowdfunding /> },
      // { path: 'realestate', element: <Realestate /> },
      // { path: 'elnet', element: <Elnet /> },
      // { path: 'heatsys', element: <Heatsys /> },
      // { path: 'gassup', element: <Gassup /> },
      // { path: 'watersup', element: <Watersup /> },
      // { path: 'landrules', element: <Landrules /> },
      // { path: 'business-protection', element: <BusinessProtection /> },
      // { path: 'business-plan', element: <BusinessPlan /> },
      // { path: 'trademark', element: <Trademark /> },
      ...almaty_business_chats_info.map((item) => {
        return {
          path: item.route,
          element: (
            <AlmatyBusinessChatPreset
              apiUrl={item.apiUrl}
              promptMessage={item.promptMessage}
              preparedMessages={item.preparedMessages}
            />
          ),
        };
      }),
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/',
    element: <RbpLanding />,
    children: [
      { path: '/main', element: <MainLanding /> },
      { path: '/payment', element: <PaymentPage /> },
    ],
  },
  // {
  //   path: '/landing',
  //   element: <Landing />,
  // },
]);

export default routes;
