import React from 'react';
import { useUnit } from 'effector-react';
import { Button, Modal } from 'antd';
import {
  $liteErrorModal,
  toggleLiteErrorModalEv,
} from '../../../models/rbpLiteModel/index.js';
import {
  changeDrawModeEv,
  changeInteractivityEv,
} from '../../../models/mapModel/index.js';

function LiteErrorModal() {
  const open = useUnit($liteErrorModal);
  const toggleLiteErrorModal = useUnit(toggleLiteErrorModalEv);
  const changeInteractivity = useUnit(changeInteractivityEv);
  const changeDrawModeState = useUnit(changeDrawModeEv);

  const onClose = () => {
    changeInteractivity(false);
    changeDrawModeState('draw_point');
    window.draw.changeMode('draw_point');
    toggleLiteErrorModal(false);
  };

  return (
    <Modal
      open={open}
      footer={null}
      closable={false}
      onCancel={onClose}
      centered
    >
      <div style={{ fontSize: 18, textAlign: 'center', marginBottom: 20 }}>
        На указанную территорию отсутствуют данные. Выберите другую локацию в
        границах города Алматы.
      </div>
      <Button
        type="primary"
        onClick={onClose}
        style={{
          display: 'block',
          margin: '0 auto',
        }}
      >
        OK
      </Button>
    </Modal>
  );
}

export default LiteErrorModal;
