import { Select } from 'antd';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import mapboxgl from 'mapbox-gl';
import { useLocation } from 'react-router-dom';
import style from './MapGeoCoder.module.css';
import { wsGetSearchOptions } from '../../utils/webSocketConfig.js';
import { $searchOptions } from '../../models/mapModel/index.js';
import { flyTo } from '../../utils/mapbox-utils.js';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { getNewLocationEv } from '../../models/rbpLiteModel/index.js';

export default function MapGeoCoder() {
  const language = useUnit($language);
  const searchOptions = useUnit($searchOptions);
  const { pathname } = useLocation();
  const getNewLocation = useUnit(getNewLocationEv);
  const isDark = useUnit($isDarkTheme);

  const [options, setOptions] = useState([]);
  const onSearch = (value) => {
    wsGetSearchOptions(
      !/Казахстан/gi.test(value) && value ? `Казахстан, ${value}` : value
    );
  };

  const onSelect = (value, option) => {
    if (window.marker) {
      window.marker.remove();
    }
    window.marker = new mapboxgl.Marker()
      .setLngLat(option.point.geometry.coordinates)
      .addTo(window.map);

    const html = `
    <div style="margin-right: 20px">
        <div style="color: ${
          isDark ? 'white' : 'black'
        }; font-size: 18px">Создать отчет для этой локации?</div>
        <button id="lite_address_button" style="margin: 10px auto 0 auto; display: block; background: #2D9CDB; color: white; border: unset; padding: 4px 15px; border-radius: 6px; cursor: pointer">Создать</button>
    </div>
    `;

    window.popup = new mapboxgl.Popup({
      anchor: 'bottom-left',
      offset: 30,
      className: isDark ? 'lite_popup_dark' : 'lite_popup_light',
    })
      .setLngLat(option.point.geometry.coordinates)
      .setHTML(html)
      .addTo(window.map);

    document
      .getElementById('lite_address_button')
      .addEventListener('click', () => {
        getNewLocation(option.point);
      });
    flyTo(option.point.geometry.coordinates, 13);
  };

  const onClear = () => {
    window?.marker?.remove();
    flyTo([76.92861, 43.10667], 9);
    setOptions([]);
  };

  useEffect(() => {
    if (Object.keys(searchOptions).length > 0) {
      setOptions(
        Object.keys(searchOptions).map((key, index) => ({
          label: searchOptions[key].long_name,
          point: searchOptions[key].geo_json,
          value: index,
        }))
      );
    } else {
      setOptions([]);
    }
  }, [searchOptions]);

  return (
    <Select
      options={options}
      className={style.geo_coder}
      style={{
        right: pathname === '/lite' ? 400 : 15,
        top: pathname === '/lite' ? 20 : 15,
      }}
      showSearch
      notFoundContent={null}
      placeholder={ru_en_page_dictionary.geocoder_placeholder[language]}
      onSearch={debounce(onSearch, 500)}
      onSelect={onSelect}
      onClear={onClear}
      allowClear
      suffixIcon={<SearchOutlined />}
      filterOption={false}
    />
  );
}
