import { sample } from 'effector';
import mapboxgl from 'mapbox-gl';
import booleanIntersects from '@turf/boolean-intersects';
import { combineEvents, condition, spread } from 'patronum';
import dayjs from 'dayjs';
import notification from 'antd/es/notification';
import {
  $activeLitePreset,
  $chartFilters,
  $chosenLiteHexagon,
  $currentFavDetails,
  $currentFavs,
  $d9Table,
  $detailsData,
  $isochroneTotalData,
  $isPreset,
  $krishaMarkers,
  $krishaObjects,
  $liteAggregates,
  $liteBusiness,
  $liteCategories,
  $liteErrorModal,
  $liteHexagons,
  $liteStartHexagons,
  $profileModal,
  $roadsData,
  $roadsStartData,
  $showRoads,
} from './stores.js';
import {
  activatePresetEv,
  changeChartFiltersEv,
  changeChosenLiteHexEv,
  changeCurrentFavsEv,
  changeLiteBusinessEv,
  deleteLitePresetEv,
  downloadReportEv,
  flyToKrishaEv,
  getNewLocationEv,
  openKrishaLinkEv,
  putD9TableEv,
  putIsochroneTotalEv,
  putKrishaObjectsEv,
  putLiteAggregatesEv,
  putLiteHexagonsEv,
  putRoadsEv,
  renamePresetEv,
  resetChosenLiteHexEv,
  restoreHexagonsEv,
  restoreRoadsEv,
  sendPaymentUserEv,
  setActivePresetEv,
  toggleIsPresetEv,
  toggleLiteErrorModalEv,
  toggleProfileModalEv,
  toggleShowRoadsEv,
} from './events.js';
import {
  $activeFilters,
  changeActiveFilterEv,
  clearGradientEv,
} from '../activeFiltersModel/index.js';
import { $zoom7Hexagons, getZeroDataFx } from '../zoom7Model/index.js';
import {
  formatDetailsData,
  formatLiteHexagons,
  mutateLiteHexagons,
} from './utils.js';
import { calculateThresholdsEv } from '../gradientModel/index.js';
import { flyTo } from '../../utils/mapbox-utils.js';
import {
  wsGetLiteData,
  wsSendPaymentUser,
} from '../../utils/webSocketConfig.js';
import filterHexagons from '../../counters/hex_counter/hex_counter.js';
import { $language, $userInfo } from '../authModel/index.js';
import RBP_LIte_index_help from '../../dictionaries/RBP_Lite_dict.json';
import {
  deleteLitePresetFx,
  downloadReportFx,
  renamePresetFx,
  sendCreateLitePresetFx,
} from './effects.js';
import { hideLoaderEv, showLoaderEv } from '../webSocketModel/index.js';
import payment_business_types from '../../data/payment_business_types.json';

$isPreset.on(toggleIsPresetEv, (state, payload) => {
  if (typeof payload === 'boolean') return payload;
  return !state;
});

$profileModal.on(toggleProfileModalEv, (state, payload) => {
  if (typeof payload === 'boolean') return payload;
  return !state;
});

$chosenLiteHexagon.reset(resetChosenLiteHexEv);

$liteAggregates.on(putLiteAggregatesEv, (_, payload) => payload);

$d9Table.on(putD9TableEv, (_, payload) => payload);

$krishaObjects.on(putKrishaObjectsEv, (_, payload) =>
  payload.map((item, index) => {
    return {
      ...item,
      key: `rent_object_${index}`,
    };
  })
);

$liteBusiness.on(changeLiteBusinessEv, (_, payload) => payload);

$activeLitePreset.on(setActivePresetEv, (_, payload) => payload);

$liteErrorModal.on(toggleLiteErrorModalEv, (state, payload) => {
  if (typeof payload === 'boolean') return payload;
  return !state;
});

sample({
  source: [
    $isochroneTotalData,
    $chosenLiteHexagon,
    $d9Table,
    $isPreset,
    $liteAggregates,
    $language,
  ],
  clock: $activeFilters.updates,
  filter: ([_, __, ___, isPreset]) =>
    isPreset && window.location.pathname === '/lite',
  fn: (
    [total, chosenHex, d9Table, _, aggregates, language],
    { chosen_metrics }
  ) => {
    const source = chosenHex ? chosenHex.properties : total;
    const d9Source = chosenHex
      ? d9Table.isochrone.find(
          (item) => chosenHex.properties.zoom9_id === item.zoom_id
        ).data
      : d9Table.city.data;
    const aggregatesSource = chosenHex ? aggregates.isochrone : aggregates.city;
    return formatDetailsData(
      source,
      chosen_metrics,
      d9Source,
      aggregatesSource,
      language
    );
  },
  target: $detailsData,
});

sample({
  source: [$liteStartHexagons, $isPreset, $liteAggregates, $showRoads],
  clock: $activeFilters.updates,
  filter: ([_, isPreset, __, showRoads]) =>
    isPreset && window.location.pathname === '/lite' && !showRoads,
  fn: ([source, _, aggregates], activeFilters) => {
    let hexByCategory = mutateLiteHexagons(
      source,
      activeFilters.chosen_metrics[0],
      [],
      aggregates.isochrone
    );
    if (activeFilters.gradient.length > 0) {
      hexByCategory = filterHexagons(hexByCategory, activeFilters, [], 'index');
    }
    return hexByCategory;
  },
  target: [$liteHexagons, calculateThresholdsEv],
});

sample({
  source: $currentFavs,
  clock: changeCurrentFavsEv,
  fn: (source, clock) => {
    if (source.includes(clock)) {
      return source.filter((item) => item !== clock);
    }
    return [...source, clock];
  },
  target: $currentFavs,
});

sample({
  source: [
    $isochroneTotalData,
    $chosenLiteHexagon,
    $d9Table,
    $liteAggregates,
    $language,
  ],
  clock: $currentFavs.updates,
  fn: ([total, chosenHex, d9Table, aggregates, language], clock) => {
    const source = chosenHex ? chosenHex.properties : total;
    const d9Source = chosenHex
      ? d9Table.isochrone.find(
          (item) => chosenHex.properties.zoom9_id === item.zoom_id
        ).data
      : d9Table.city.data;
    const aggregatesSource = chosenHex ? aggregates.isochrone : aggregates.city;
    return formatDetailsData(
      source,
      clock,
      d9Source,
      aggregatesSource,
      language
    );
  },
  target: $currentFavDetails,
});

sample({
  source: $chartFilters,
  clock: changeChartFiltersEv,
  fn: (source, clock) => {
    if (
      source.some((item) => {
        return Object.keys(item).every((field) => item[field] === clock[field]);
      })
    ) {
      return source.filter((item) => {
        return !Object.keys(item).every(
          (field) => item[field] === clock[field]
        );
      });
    }
    return [...source, clock];
  },
  target: [$chartFilters, clearGradientEv],
});

sample({
  clock: changeActiveFilterEv,
  filter: (clock) =>
    window.location.pathname === '/lite' && clock.field === 'chosen_metrics',
  fn: () => [],
  target: $chartFilters,
});

const combinePutHexAgg = combineEvents({
  events: {
    hexagons: putLiteHexagonsEv,
    aggregates: putLiteAggregatesEv,
  },
});

sample({
  clock: combinePutHexAgg,
  fn: (clock) => {
    return formatLiteHexagons(clock.hexagons, clock.aggregates);
  },
  target: [$liteHexagons, $liteStartHexagons, calculateThresholdsEv],
});

sample({
  source: $liteHexagons,
  clock: $isPreset.updates,
  filter: (source, clock) => clock,
  fn: (source) => source,
  target: calculateThresholdsEv,
});

sample({
  clock: putIsochroneTotalEv,
  fn: (clock) => {
    return {
      ...clock,
      d11i1: +(clock.d11i1 * 10).toFixed(1),
      d11i2: +(clock.d11i2 * 10).toFixed(1),
      d11i3: +(clock.d11i3 * 10).toFixed(1),
      d11i4: +(clock.d11i4 * 10).toFixed(1),
    };
  },
  target: $isochroneTotalData,
});

sample({
  source: [$activeFilters, $language],
  clock: putIsochroneTotalEv,
  fn: ([activeFilters, language], clock) =>
    formatDetailsData(
      clock,
      activeFilters.chosen_metrics,
      undefined,
      undefined,
      language
    ),
  target: $detailsData,
});

sample({
  source: $chosenLiteHexagon,
  clock: changeChosenLiteHexEv,
  fn: (source, clock) => {
    if (source && source.id === clock.id) {
      return null;
    }
    return clock;
  },
  target: $chosenLiteHexagon,
});

sample({
  source: [
    $isochroneTotalData,
    $activeFilters,
    $d9Table,
    $liteAggregates,
    $language,
  ],
  clock: $chosenLiteHexagon.updates,
  fn: ([total, activeFilters, d9Table, aggregates, language], clock) => {
    const source = clock ? clock.properties : total;
    const d9Source = clock
      ? d9Table.isochrone.find(
          (item) => clock.properties.zoom9_id === item.zoom_id
        ).data
      : d9Table.city.data;
    const aggregatesSource = clock ? aggregates.isochrone : aggregates.city;
    return formatDetailsData(
      source,
      activeFilters.chosen_metrics,
      d9Source,
      aggregatesSource,
      language
    );
  },
  target: $detailsData,
});

sample({
  source: [
    $isochroneTotalData,
    $chosenLiteHexagon,
    $currentFavs,
    $d9Table,
    $liteAggregates,
    $language,
  ],
  clock: $chosenLiteHexagon.updates,
  fn: ([total, chosenHex, favs, d9Table, aggregates, language], clock) => {
    const source = chosenHex ? chosenHex.properties : total;
    const d9Source = chosenHex
      ? d9Table.isochrone.find(
          (item) => chosenHex.properties.zoom9_id === item.zoom_id
        ).data
      : d9Table.city.data;
    const aggregatesSource = chosenHex ? aggregates.isochrone : aggregates.city;
    return formatDetailsData(
      source,
      favs,
      d9Source,
      aggregatesSource,
      language
    );
  },
  target: $currentFavDetails,
});

sample({
  source: $liteCategories,
  clock: putLiteAggregatesEv,
  fn: (source, clock) => {
    return source.map((item) => {
      let value = Math.round(clock.city[item.index_name].current);
      let avg = Math.round(clock.city[item.index_name].mean);
      let total = Math.round(clock.city[item.index_name].max);
      let sub_value;
      let sub_avg;

      if (item.index_name === 'd11') {
        value = Math.round(clock.city[item.index_name].current * 10);
        avg = Math.round(clock.city[item.index_name].mean * 10);
        total = 10;
      }

      if (item.index_name === 'd10') {
        value = clock.city.d10ln.current;
        avg = clock.city.d10ln.mean;
        total = clock.city.d10ln.max;
        sub_value = Math.round(clock.city.d10.current);
        sub_avg = Math.round(clock.city.d10.mean);
      }

      if (item.index_name === 'd7') {
        value = Math.round(clock.city.d7t.current);
        avg = Math.round(clock.city.d7t.mean);
        total = Math.round(clock.city.d7t.max);
      }

      return {
        ...item,
        value,
        avg,
        total,
        sub_value,
        sub_avg,
      };
    });
  },
  target: $liteCategories,
});

sample({
  source: [$liteAggregates, $liteCategories],
  clock: $chosenLiteHexagon.updates,
  fn: ([aggregates, categories], chosen) => {
    const aggSource = chosen ? aggregates.isochrone : aggregates.city;

    return categories.map((item) => {
      let value = chosen
        ? Math.round(
            aggSource[item.index_name].current[chosen.properties.zoom9_id]
          )
        : Math.round(aggSource[item.index_name].current);
      let avg = Math.round(aggSource[item.index_name].mean);
      let total = Math.round(aggSource[item.index_name].max);

      let sub_value;
      let sub_avg;

      if (item.index_name === 'd11') {
        value = chosen
          ? Math.round(
              aggSource[item.index_name].current[chosen.properties.zoom9_id] *
                10
            )
          : Math.round(aggSource[item.index_name].current) * 10;
        avg = Math.round(aggSource[item.index_name].mean * 10);
        total = 10;
      }

      if (item.index_name === 'd10') {
        value = chosen
          ? aggSource.d10ln.current[chosen.properties.zoom9_id]
          : aggSource.d10ln.current;
        avg = aggSource.d10ln.mean;
        total = aggSource.d10ln.max;
        sub_value = chosen
          ? Math.round(aggSource.d10.current[chosen.properties.zoom9_id])
          : Math.round(aggSource.d10.current);
        sub_avg = Math.round(aggSource.d10.mean);
      }

      return {
        ...item,
        value,
        avg,
        total,
        sub_value,
        sub_avg,
      };
    });
  },
  target: $liteCategories,
});

sample({
  source: [$liteHexagons, $activeFilters, $isPreset],
  clock: $chartFilters.updates,
  filter: ([_, __, isPreset]) => isPreset,
  fn: ([hexagons, { chosen_metrics }], clock) => {
    const withBt = ['d7', 'd8'];
    const specKeys = [];
    clock.forEach((filter) => {
      let prop;
      if (filter.metrics === 'd6' || filter.metrics === 'd8') {
        prop = `${filter.metrics}${filter.category}`;
      } else if (filter.metrics === 'd10' || filter.metrics === 'd5') {
        prop = `${filter.metrics}${filter.column}${filter.category}`;
      } else {
        prop = `${filter.metrics}${filter.category}${filter.column}`;
      }
      if (withBt.includes(filter.metrics)) {
        prop += filter.bt;
      }
      specKeys.push(prop);
    });
    return mutateLiteHexagons(hexagons, chosen_metrics[0], specKeys);
  },
  target: [$liteHexagons, calculateThresholdsEv],
});

sample({
  source: $krishaMarkers,
  clock: flyToKrishaEv,
  fn: (source, clock) => {
    if (
      source &&
      source._lngLat.lng === clock.coords[0] &&
      source._lngLat.lat === clock.coords[1] &&
      source._element.className.includes(`marker_${clock.id}`)
    ) {
      source.remove();
      flyTo(clock.coords, 11);
      return null;
    }
    if (source) {
      source.remove();
    }
    flyTo(clock.coords, 15);
    return new mapboxgl.Marker({
      test: 'test',
    })
      .setLngLat(clock.coords)
      .addClassName(`marker_${clock.id}`)
      .addTo(window.map);
  },
  target: $krishaMarkers,
});

sample({
  clock: $isPreset.updates,
  fn: (isPreset) => {
    if (isPreset) {
      return {
        field: 'chosen_metrics',
        value: 'd1',
      };
    }
    return {
      field: 'chosen_metrics',
      value: `competitors_cnt_B`,
    };
  },
  target: changeActiveFilterEv,
});

sample({
  source: [$zoom7Hexagons, $liteBusiness, $userInfo],
  clock: getNewLocationEv,
  filter: ([_, __, userInfo]) => userInfo.presets_all !== userInfo.presets_used,
  fn: ([hexagons, liteBusiness], point) => {
    const uuid = `t${liteBusiness.business}pt${liteBusiness.sub_business}`;
    const category = payment_business_types.find((type) => {
      return type.subcategories.find((elem) => elem.uuid === uuid);
    });

    if (window.marker) {
      window.marker.remove();
    }
    if (window.popup) {
      window.popup.remove();
    }

    return {
      lon: point.geometry.coordinates[0],
      lat: point.geometry.coordinates[1],
      ...liteBusiness,
      name: `${category.type}, ${
        category.subcategories.find((subtype) => subtype.uuid === uuid).subtype
      }_${dayjs().format('YYYY-MM-DD HH:mm')}`,
    };
  },
  target: [sendCreateLitePresetFx, showLoaderEv],
});

sample({
  source: $userInfo,
  clock: getNewLocationEv,
  filter: (userInfo) => userInfo.presets_all === userInfo.presets_used,
  fn: () => true,
  target: toggleProfileModalEv,
});

sample({
  source: $userInfo,
  clock: sendCreateLitePresetFx.doneData,
  filter: (source, clock) => Object.keys(clock).length > 0,
  fn: (source, clock) => {
    return {
      userInfo: {
        ...source,
        presets_used: source.presets_used + 1,
        presets: {
          ...source.presets,
          rbp_lite: [
            ...source.presets.rbp_lite,
            {
              id: clock.id,
              ...clock.data,
            },
          ],
        },
      },
      activatePreset: clock.data.isochrones,
    };
  },
  target: spread({
    targets: {
      userInfo: $userInfo,
      activatePreset: [toggleIsPresetEv, activatePresetEv, hideLoaderEv],
    },
  }),
});

sample({
  clock: sendCreateLitePresetFx.doneData,
  filter: (clock) => Object.keys(clock).length === 0,
  fn: () => true,
  target: [toggleLiteErrorModalEv, hideLoaderEv],
});

sample({
  clock: activatePresetEv,
  fn: (clock) => {
    wsGetLiteData(clock);
  },
});

sample({
  clock: $activeLitePreset.updates,
  fn: (clock) => {
    return {
      business: clock.business,
      sub_business: clock.sub_business,
    };
  },
  target: changeLiteBusinessEv,
});

sample({
  clock: putRoadsEv,
  fn: (clock) => {
    return clock.map((road) => {
      return {
        ...road,
        properties: {
          ...road.properties,
          index_main: road.properties.d5_lines,
        },
      };
    });
  },
  target: [$roadsData, $roadsStartData],
});

sample({
  source: $showRoads,
  clock: toggleShowRoadsEv,
  fn: (source, clock) => {
    if (typeof clock === 'boolean') return clock;
    return !source;
  },
  target: $showRoads,
});

condition({
  source: $showRoads,
  if: (showRoads) => showRoads,
  then: restoreRoadsEv,
  else: restoreHexagonsEv,
});

sample({
  source: [$liteStartHexagons, $liteAggregates],
  clock: restoreHexagonsEv,
  fn: ([hexagons, aggregates]) => {
    return mutateLiteHexagons(hexagons, 'd5', []);
  },
  target: [$liteHexagons, calculateThresholdsEv],
});

sample({
  source: $roadsStartData,
  clock: restoreRoadsEv,
  fn: (source) => source,
  target: [$roadsData, calculateThresholdsEv],
});

sample({
  source: [$roadsStartData, $showRoads],
  clock: $activeFilters.updates,
  filter: ([_, showRoads]) => showRoads,
  fn: ([roads, _], activeFilters) => {
    return filterHexagons(roads, activeFilters, [], 'index');
  },
  target: $roadsData,
});

sample({
  source: [$detailsData, $liteCategories],
  clock: $language.updates,
  fn: ([details, categories], language) => {
    return {
      details: details.map((detail) => {
        return {
          ...detail,
          info: RBP_LIte_index_help[detail.metric.replace('d', '')][
            `description_${language}`
          ],
          title:
            RBP_LIte_index_help[detail.metric.replace('d', '')][
              `name_${language}`
            ],
        };
      }),
      categories: categories.map((category) => {
        return {
          ...category,
          info: RBP_LIte_index_help[category.index_name.replace('d', '')][
            `description_${language}`
          ],
          title:
            RBP_LIte_index_help[category.index_name.replace('d', '')][
              `name_${language}`
            ],
        };
      }),
    };
  },
  target: spread({
    targets: {
      details: $detailsData,
      categories: $liteCategories,
    },
  }),
});

sample({
  clock: downloadReportEv,
  target: downloadReportFx,
});

sample({
  clock: downloadReportFx.doneData,
  fn: (clock) => {
    console.log(clock);
  },
});

sample({
  clock: renamePresetEv,
  fn: (clock) => {
    return {
      id: clock.id,
      data: {
        name: clock.value,
      },
    };
  },
  target: renamePresetFx,
});

sample({
  source: $userInfo,
  clock: renamePresetFx.doneData,
  fn: (source, clock) => {
    const current_preset = source.presets.rbp_lite.find(
      (preset) => preset.id === clock.id
    );
    const presetIndex = source.presets.rbp_lite.findIndex(
      (preset) => preset.id === clock.id
    );

    return {
      ...source,
      presets: {
        ...source.presets,
        rbp_lite: [
          ...source.presets.rbp_lite.slice(0, presetIndex),
          {
            ...current_preset,
            name: clock.data.name,
          },
          ...source.presets.rbp_lite.slice(presetIndex + 1),
        ],
      },
    };
  },
  target: $userInfo,
});

sample({
  clock: deleteLitePresetEv,
  target: deleteLitePresetFx,
});

sample({
  source: $userInfo,
  clock: deleteLitePresetFx.doneData,
  fn: (source, clock) => {
    return {
      ...source,
      presets_used: source.presets_used - 1,
      presets: {
        ...source.presets,
        rbp_lite: source.presets.rbp_lite.filter(
          (preset) => preset.id !== clock
        ),
      },
    };
  },
  target: $userInfo,
});

sample({
  clock: sendPaymentUserEv,
  fn: (clock) => {
    wsSendPaymentUser({
      // product: clock,
      product: 'location_test',
    });
  },
});

sample({
  source: $activeLitePreset,
  clock: openKrishaLinkEv,
  fn: (source) => window.open(source.krisha_url, '_blank'),
});
