export const egov_tax_modes_info = [
  {
    id: 1,
    title: 'Общеустановленный режим налогообложения',
    subtitle: 'Подходит и для ИП, и для юридических лиц.',
    conditions: [
      'Количество сотрудников: не ограничено',
      'Предельный доход: не ограничен',
      'Ставка КПН для ТОО: 20% от прибыли (для сельхозпроизводителей – 10%)Ставка ИПН для ИП: 10% от прибыли',
      'Виды деятельности: не ограничены',
    ],
    pros: [
      'Подходит предпринимателям с небольшой наценкой, а также тем, кто занимается импортом и дальнейшей реализацией;',
      'Крупный бизнес предпочитает работать с ТОО на общеустановленном режиме с НДС;',
    ],
    cons: [
      'Требуется вести полный бухгалтерский учет;',
      'На текущий момент сложно с поставщиками с официальными документами;',
      'Нет дополнительных вычетов на экстренные расходы;',
      'Порог по НДС ограничен 20 000 МРП;',
      'Высокая нагрузка по фонду заработной платы – 39%.',
    ],
    allowed_activities: [],
  },
  {
    id: 2,
    title: 'Специальный режим с использованием фиксированного вычета',
    subtitle:
      'Подходит и для ИП, и для юридических лиц в форме малого предпринимательства.',
    conditions: [
      'Количество сотрудников: до 50',
      'Предельный доход: не превышает 144 184-кратный размер МРП за год.',
      'Ставка КПН для ТОО: 20% от дохода',
      'Ставка ИПН для ИП: 10% от доходаи',
    ],
    pros: [
      'Позволяет брать на вычеты без подтверждающих документов, тем самым снижая нагрузку на бухгалтерию, в т.ч. по расходам, на которые невозможно получить документы;',
      'Количество работников и предельный Доход больше, чем на Упрощенной декларации;',
      'Если средняя з/п более 47 МРП, то уменьшение налогооблагаемого Дохода на общий размер ФОТ;',
    ],
    cons: [
      'Порог по НДС остается 20 000 МРП Исключены расходы по производству (сырье, материалы, услуги);',
      'Ограничение по общим вычетам не более 70% расходов практически приравнивает к Упрощенке, с низкой маржой нет выгоды;',
      'Бух учет сложнее, чем по Упрощенке',
      'Непопулярный налоговый режим',
    ],
    allowed_activities: [],
  },
  {
    id: 3,
    title: 'Упрощенный режим налогообложения',
    subtitle: 'Подходит и для ИП, и для юридических лиц.',
    conditions: [
      'Налогом облагается доход компании без учета расходов.',
      'Количество сотрудников: до 30',
      'Предельный доход: до 24 038 МРП за полугодие.',
      'При превышении Вы автоматически переходите на другой налоговый режим.',
      'Ставка КПН для ТОО: 3% от дохода',
      'Ставка ИПН для ИП: 3% от дохода (без учета расходов)',
      'Виды деятельности: не ограничены',
      'Также, данный режим не могут использовать предприниматели, имеющие структурные подразделения, а также филиалы и объекты обложения в разных населенных пунктах.',
    ],
    pros: [
      'Самый популярный налоговый режим​реализацией​;​​​',
      'Можно работать с юридическими лицами​;',
      'Не требуется ведение бухгалтерского учета (при этом сбор первичных документов обязателен!);',
      'Налог уменьшается на размер социальных отчислений работников​;',
      'Налог уменьшается на 1,5% за каждого работника, если средняя зарплата более 23МРП - 84 916 тенге (для ИП) или 29МРП – 107 068 тенге (для ТОО)(Приложение №4);',
    ],
    cons: [
      'Порог по НДС ниже Предельного дохода по Упрощенному режиму, поэтому возникают 15% штрафы за превышение порога по НДС;',
      'При превышении порога НДС жесткий переход на НДС, очень сложно работать с +12% НДС, почти налог с Оборота;',
      'При минимальных оборотах высокая нагрузка из-за социальных платежей, т.к. минимальный платеж за пенсионные, социальные отчисления и соц мед страхование – 18 200 тенге(Приложение №2)',
      'Есть ограничения по применению Упрощенной декларации (Приложение №3)',
    ],
    allowed_activities: [],
  },
  {
    id: 4,
    title: 'Специальный налоговый режим на основе патента',
    subtitle:
      'Доступен только для индивидуальных предпринимателей в форме личного предпринимательства.',
    conditions: [
      'Количество сотрудников - не предусмотрено',
      'Предельный доход - не должен превышать 13 025 376 тенге 3 528 МРП за год. При превышении Вы автоматически переходите на другой налоговый режим',
      'Ставка ИПН – 1% к доходу, полученному за налоговый период + пенсионные, социальные отчисления и мед. cтрахование за ИП.',
      'Не являются плательщиками НДС',
      'На основе патента форма 911.00, оплата налога и соц. платежей до получения Патента – 1 раз в год.',
      'C использованием мобильного приложения позволяет сдать отчет',
    ],
    pros: [
      'Относительно низкий налог - 1%;',
      'Можно применять при оказании услуг юридическим лицам;',
      'По Мобильному приложению имеется предварительное декларирование, чеки без комиссии ОФД.',
    ],
    cons: [
      'По патенту требуется заранее оплатить налог и социальные платежи. По итогам года требуется корректировать налог;',
      'При минимальных оборотах высокая нагрузка из-за социальных платежей, т.к. минимальный платеж за пенсионные, социальные отчисления и соц мед страхование – 18 200 тенге',
    ],
    allowed_activities: [
      'Штукатурные работы',
      'Столярные и плотницкие работы',
      'Работы по покрытию полов и облицовке стен',
      'Малярные и стекольные работы',
      'Деятельность такси',
      'Грузовые перевозки автомобильным транспортом',
      'Управление недвижимостью за вознаграждение или на договорной основе',
      'Деятельность в области фотографии',
      'Переводческое (устное и письменное) дело',
      'Сдача в имущественный наем (аренду)',
      'Сдача в имущественный наем (аренду) транспортных средств',
      'Прокат и имущественный наем (аренда) развлекательного и спортивного инвентаря',
      'Прокат видеозаписей и дисков',
      'Прокат и имущественный наем (аренда) прочих предметов личного потребления и бытовых товаров',
      'Сдача в имущественный наем (аренду) сельскохозяйственной техники и оборудования',
      'Сдача в имущественный наем (аренду) офисных машин и оборудования, включая вычислительную технику',
      'Услуги в области спортивного образования и образования специалистов организации досуга',
      'Услуги образования в сфере культуры',
      'Услуги в области прочего образования',
      'Вспомогательные образовательные услуги',
      'Деятельность в области искусства',
      'Ремонт компьютеров и периферийного оборудования',
      'Ремонт коммуникационного оборудования',
      'Ремонт предметов личного потребления и бытовых товаров',
      'Парикмахерские услуги',
      'Маникюр и педикюр',
      'Ветеринарные услуги',
      'Услуги по обработке земельных участков',
      'Услуги по уборке жилых помещений и ведению домашнего хозяйства',
      'Услуги носильщиков на рынках, вокзалах',
      'Изготовление и ремонт музыкальных инструментов',
      'Выпас домашних животных',
      'Курьерская доставка, за исключением услуг по доставке почтовых отправлений (только для Мобильного приложения)',
    ],
  },
];
