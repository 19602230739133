import React, { useCallback } from 'react';
import {
  Button,
  Collapse,
  Input,
  Popover,
  Radio,
  Space,
  Table,
  Tooltip,
} from 'antd';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import mapboxgl from 'mapbox-gl';
import style from './RentSuggestions.module.css';
import {
  $isDarkTheme,
  $language,
  $userInfo,
} from '../../../models/authModel/index.js';
import {
  $krishaMarkers,
  $krishaObjects,
  flyToKrishaEv,
  openKrishaLinkEv,
} from '../../../models/rbpLiteModel/index.js';
import { lite_krisha_types } from '../../../data/lite_krisha_types.js';
import { addSeparators } from '../../../utils/format-utils.js';
import RBP_LIte_index_help from '../../../dictionaries/RBP_Lite_dict.json';

function LinkSvg() {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84294 6.15402C10.3032 6.37378 10.7041 6.7008 11.0118 7.10761C11.3195 7.51441 11.525 7.98911 11.6112 8.49184C11.6974 8.99457 11.6617 9.51065 11.5071 9.99671C11.3525 10.4828 11.0835 10.9246 10.7227 11.2852L7.53519 14.4727C6.93741 15.0705 6.12665 15.4063 5.28127 15.4063C4.43589 15.4063 3.62513 15.0705 3.02735 14.4727C2.42958 13.8749 2.09375 13.0642 2.09375 12.2188C2.09375 11.3734 2.42958 10.5626 3.02735 9.96485L4.27189 8.72031M13.7281 8.27973L14.9727 7.03519C15.5705 6.43741 15.9063 5.62665 15.9063 4.78127C15.9063 3.93589 15.5705 3.12513 14.9727 2.52735C14.3749 1.92958 13.5642 1.59375 12.7188 1.59375C11.8734 1.59375 11.0626 1.92958 10.4649 2.52735L7.27735 5.71485C6.91655 6.0754 6.64755 6.51726 6.49293 7.00333C6.33832 7.48939 6.30261 8.00547 6.3888 8.5082C6.47499 9.01093 6.68058 9.48563 6.98828 9.89243C7.29598 10.2992 7.69681 10.6263 8.1571 10.846"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function LinkIcon(props) {
  return <Icon component={LinkSvg} {...props} />;
}

function MarkSvg() {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.125 7.4375C11.125 8.00108 10.9011 8.54159 10.5026 8.9401C10.1041 9.33862 9.56358 9.5625 9 9.5625C8.43641 9.5625 7.89591 9.33862 7.4974 8.9401C7.09888 8.54159 6.875 8.00108 6.875 7.4375C6.875 6.87391 7.09888 6.33341 7.4974 5.9349C7.89591 5.53638 8.43641 5.3125 9 5.3125C9.56358 5.3125 10.1041 5.53638 10.5026 5.9349C10.9011 6.33341 11.125 6.87391 11.125 7.4375Z"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3125 7.4375C14.3125 12.4964 9 15.4062 9 15.4062C9 15.4062 3.6875 12.4964 3.6875 7.4375C3.6875 6.02854 4.24721 4.67728 5.2435 3.681C6.23978 2.68471 7.59104 2.125 9 2.125C10.409 2.125 11.7602 2.68471 12.7565 3.681C13.7528 4.67728 14.3125 6.02854 14.3125 7.4375Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function MarkIcon(props) {
  return <Icon component={MarkSvg} {...props} />;
}

function RentSuggestions() {
  const isDark = useUnit($isDarkTheme);
  const data = useUnit($krishaObjects);
  const flyToKrisha = useUnit(flyToKrishaEv);
  const krishaMarkers = useUnit($krishaMarkers);
  const language = useUnit($language);
  const userInfo = useUnit($userInfo);
  const openKrishaLink = useUnit(openKrishaLinkEv);

  const addSortAndFilter = useCallback(
    (field) => {
      return {
        filterSearch: true,
        filters: Object.keys(lite_krisha_types).map((item) => {
          return {
            text: lite_krisha_types[item],
            value: item,
          };
        }),
        onFilter: (value, record) => {
          if (typeof value === 'object') {
            switch (value.type) {
              case '>':
                return (
                  record[field] >
                  (typeof value.value === 'string'
                    ? Number(value.value)
                    : value.value)
                );
              case '<':
                return (
                  record[field] <
                  (typeof value.value === 'string'
                    ? Number(value.value)
                    : value.value)
                );
              case '=':
                return (
                  record[field] ===
                  (typeof value.value === 'string'
                    ? Number(value.value)
                    : value.value)
                );
            }
          } else {
            return record[field].includes(value);
          }
        },
        sorter: (a, b) => {
          if (typeof a[field] === 'string') {
            return a[field].localeCompare(b[field]);
          }
          return a[field] - b[field];
        },
        filterDropdown:
          field === 'floor' ||
          field === 'square' ||
          field === 'price_full' ||
          field === 'price_m2'
            ? ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                close,
              }) => (
                <div
                  style={{
                    padding: 5,
                  }}
                >
                  <Input
                    value={[selectedKeys[0]?.value]}
                    onChange={(e) =>
                      setSelectedKeys(
                        e.target.value
                          ? [
                              {
                                value: e.target.value,
                                type: selectedKeys[0]?.type,
                              },
                            ]
                          : []
                      )
                    }
                    style={{
                      marginBottom: 5,
                    }}
                  />
                  <Radio.Group
                    value={selectedKeys[0]?.type || null}
                    onChange={(e) => {
                      setSelectedKeys(
                        e.target.value
                          ? [
                              {
                                value: selectedKeys[0]?.value,
                                type: e.target.value,
                              },
                            ]
                          : []
                      );
                    }}
                  >
                    <Radio value=">">{'>'}</Radio>
                    <Radio value="<">{'<'}</Radio>
                    <Radio value="=">=</Radio>
                  </Radio.Group>
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (selectedKeys[0].value && selectedKeys[0].type) {
                          confirm();
                        }
                      }}
                      type="primary"
                    >
                      OK
                    </Button>
                    <Button
                      onClick={() => {
                        clearFilters();
                        setSelectedKeys([]);
                        confirm();
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              )
            : null,
      };
    },
    [data]
  );

  const columns = [
    {
      title: 'Тип помещения',
      dataIndex: 'category_id',
      key: 'type',
      render: (value, record) => {
        return (
          <Tooltip
            title={value.map((item) => lite_krisha_types[item]).join(', ')}
          >
            {value.length > 3
              ? `${value
                  .slice(0, 3)
                  .map((item) => lite_krisha_types[item])
                  .join(', ')}...`
              : value.map((item) => lite_krisha_types[item]).join(', ')}
          </Tooltip>
        );
      },
      ...addSortAndFilter('category_id'),
    },
    {
      title: 'Этаж',
      dataIndex: 'floor',
      key: 'floor',
      render: (value) => value || 'Не указан',
      ...addSortAndFilter('floor'),
    },
    {
      title: 'Площадь',
      dataIndex: 'square',
      key: 'square',
      ...addSortAndFilter('square'),
    },
    {
      title: 'Стоимость общая',
      dataIndex: 'price_full',
      key: 'price_full',
      render: (value, record) => addSeparators(value),
      ...addSortAndFilter('price_full'),
    },
    {
      title: 'Стоимость за м2',
      dataIndex: 'price_m2',
      key: 'price_m2',
      render: (value, record) => addSeparators(value),
      ...addSortAndFilter('price_m2'),
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => {
        let color = 'inherit';

        if (krishaMarkers) {
          if (
            krishaMarkers._lngLat.lng === record.lon &&
            krishaMarkers._lngLat.lat === record.lat &&
            krishaMarkers._element.className.includes(`marker_${record.id}`)
          ) {
            color = '#1890ff';
          }
        }
        return (
          <Space>
            <MarkIcon
              style={{
                color,
              }}
              onClick={() => {
                flyToKrisha({
                  coords: [record.lon, record.lat],
                  id: record.id,
                });
              }}
            />
            <LinkIcon
              onClick={() => {
                window.open(record.url, '_blank');
              }}
            />
          </Space>
        );
      },
    },
  ];

  const popoverContent = (
    <div
      style={{
        maxWidth: 600,
      }}
    >
      <div className={style.popover_title}>
        {RBP_LIte_index_help['12'][`name_${language}`]}
      </div>
      {RBP_LIte_index_help['12'][`description_${language}`]?.match(/<.+?>/g) ? (
        <div
          className={style.popover_info}
          dangerouslySetInnerHTML={{
            __html: RBP_LIte_index_help['12'][`description_${language}`],
          }}
        />
      ) : (
        <div className={style.popover_info}>
          {RBP_LIte_index_help['12'][`description_${language}`]}
        </div>
      )}
    </div>
  );

  if (!userInfo?.krisha_enabled) {
    return (
      <Button
        type="primary"
        className={style.rent_link_button}
        onClick={openKrishaLink}
      >
        Посмотреть актуальные предложения об аренде
      </Button>
    );
  }

  return (
    <div
      className={style.rent_suggestions}
      style={{ background: isDark ? '#1e2328' : 'white' }}
    >
      <Collapse
        expandIconPosition="end"
        ghost
        items={[
          {
            label: (
              <label>
                Актуальные предложения об аренде{' '}
                <Popover content={popoverContent} placement="left">
                  <InfoCircleOutlined className={style.details_info} />
                </Popover>
              </label>
            ),
            children: (
              <Table
                dataSource={data}
                columns={columns}
                size="small"
                style={{ width: 800 }}
                scroll={{
                  y: 400,
                }}
                pagination={false}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default RentSuggestions;
