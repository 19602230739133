import React, { useState } from 'react';
import {
  Button,
  Divider,
  Popover,
  Radio,
  Select,
  Space,
  TreeSelect,
} from 'antd';
import { useUnit } from 'effector-react';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import style from './PublicBusinessType.module.css';
import { public_index_dictionary } from '../../dictionaries/public_index_dictionary.js';
import {
  $chosenZoom7Hexagon,
  $clickCoords,
  $publicBusinessType,
  changePublicBusinessTypeEv,
} from '../../models/zoom7Model/index.js';
import { $isDarkTheme } from '../../models/authModel/index.js';
import payment_business_types from '../../data/payment_business_types.json';
import {
  $activeLitePreset,
  $liteBusiness,
  changeLiteBusinessEv,
} from '../../models/rbpLiteModel/index.js';

function PublicBusinessType() {
  const publicBusinessType = useUnit($publicBusinessType);
  const changePublicBusinessType = useUnit(changePublicBusinessTypeEv);
  const chosenZoom7Hexagon = useUnit($chosenZoom7Hexagon);
  const clickCoords = useUnit($clickCoords);
  const { pathname } = useLocation();
  const isDark = useUnit($isDarkTheme);
  const [treeExpanded, setTreeExpanded] = useState([]);
  const changeLiteBusiness = useUnit(changeLiteBusinessEv);
  const liteBusiness = useUnit($liteBusiness);
  const [animation, setAnimation] = useState(true);

  const onSelect = (value) => {
    if (treeExpanded.includes(value)) {
      setTreeExpanded([...treeExpanded].filter((item) => item !== value));
    } else {
      setTreeExpanded([...treeExpanded, value]);
    }
  };

  const onGetFull = () => {
    let url = 'https://rbp.qala-ai.com/payment';
    if (clickCoords) {
      url += `?lat=${clickCoords.lat}&lng=${clickCoords.lng}`;
    }
    window.open(url, '_blank');
  };

  const onTreeChange = (value) => {
    const business = value.split('pt')[0].slice(1);
    const sub_business = value.split('pt')[1];
    changePublicBusinessType(`competitors_cnt_B${business}`);
    changeLiteBusiness({
      business,
      sub_business,
    });
  };

  const business_types = payment_business_types.map((item) => {
    return {
      title: (
        <span
          style={{
            color: isDark ? '#fff' : '#000',
            cursor: 'pointer',
            width: '100%',
            display: 'block',
          }}
          onClick={() => {
            onSelect(item.type);
          }}
        >
          {item.type}
        </span>
      ),
      value: item.type,
      disabled: true,
      children: item.subcategories.map((sub) => {
        return {
          value: sub.uuid,
          title: sub.subtype,
        };
      }),
    };
  });

  return (
    <div
      className={style.public_business_type}
      style={{
        background: isDark ? 'rgb(30, 35, 40)' : '#fff',
        animation: animation ? `${style.pulse} 2s infinite` : 'unset',
      }}
      onMouseEnter={() => setAnimation(false)}
    >
      <div
        className={style.public_business_type_title}
        style={{
          color: isDark ? '#fff' : '#000',
        }}
      >
        Тип бизнеса
      </div>
      {pathname !== '/lite' ? (
        <Select
          value={publicBusinessType}
          className={style.public_business_type_select}
          onChange={changePublicBusinessType}
          suffixIcon={<SearchOutlined />}
          options={Object.keys(public_index_dictionary)
            .filter((item) => /competitors_cnt_B\d{1}/g.test(item))
            .map((item) => ({
              label: public_index_dictionary[item],
              value: item,
            }))}
        />
      ) : (
        <TreeSelect
          className={style.payments_form_input}
          treeData={business_types}
          onTreeExpand={(keys) => setTreeExpanded(keys)}
          treeExpandedKeys={treeExpanded}
          onChange={onTreeChange}
          defaultValue={`t${liteBusiness.business}pt${liteBusiness.sub_business}`}
        />
      )}
      {pathname !== '/lite' ? (
        <>
          <Divider style={{ margin: '15px 0' }} />
          <div className={style.public_business_desc}>
            Также вы можете получить больше данных с высокой детализацией
          </div>
          <Button
            type="primary"
            // disabled={!chosenZoom7Hexagon}
            className={style.get_full_button}
            onClick={onGetFull}
          >
            Получить больше данных
          </Button>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default PublicBusinessType;
