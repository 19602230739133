import React, { useState, useEffect, useRef } from 'react';
import { CopyOutlined, SendOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import style from '../../AlmatyBusinessChats/AlmatyBusinessChatPreset/AlmatyBusinessChatPreset.module.css';
import ai_prep_message from '../../../assets/ai_prep_message.svg';
import ai_prep_bigger from '../../../assets/ai_prep_bigger.svg';
// FIXME RCP URL FOR AI ASSISTANT
// const apiUrl = 'https://asst.metatable.ai/create_message'
// const apiUrl = 'https://atamekenvc.metatable.ai/create_message';
const authToken =
  '2b993bb5aa86d16f300224326e7f864804ddb99b2dd91f017367bc5c9aae5621';

function ChatV2(props) {
  const { apiUrl, promptMessage, preparedMessages } = props;

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [containerHeight, setContainerHeight] = useState('100vh');
  const [addMargin, setAddMargin] = useState(false);
  const msgContainer = useRef();
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    // Добавляем стартовое сообщение при загрузке виджета
    const initialMessage = {
      text: promptMessage,
      fromUser: false,
    };
    setMessages([initialMessage]);
  }, []);
  function processText(input) {
    // Разбиваем текст на строки
    const lines = input.split(/(?<=\\.)\\d+\\.\\s/); // Разделяем по номеру с точкой и пробелом
    const result = [];

    lines.forEach((line) => {
      // Убираем `**` и заменяем содержимое внутри `【】` на пустую строку
      const cleanedLine = line
        .replace(/\\*\\*/g, '')
        .replace(/【[^】]*】/g, '')
        .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
        .replace(/(\s\d+\.)/g, '<br/>$1')
        .replace(/(\s-\s)/g, '<br/>$1');

      if (cleanedLine.trim()) {
        result.push(cleanedLine.trim());
      }
    });

    // Возвращаем текст с новой строки для каждого пункта
    return result.join('\\n');
  }

  const sendMessage = async (msg) => {
    const text = typeof msg === 'string' ? msg : input;

    if (!text.trim()) return;

    // Отображаем исходящее сообщение
    const newMessages = [...messages, { text, fromUser: true }];
    setMessages(newMessages);
    setInput('');
    setIsTyping(true);
    setTimeout(() => {
      if (msgContainer.current) {
        msgContainer.current.scrollTop = msgContainer.current.scrollHeight;
      }
    }, 0);
    // Отправляем сообщение в API
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          message: text,
          thread_id: threadId,
        }),
      });
      const data = await response.json();

      // Сохраняем thread_id при первом ответе
      if (!threadId) {
        setThreadId(data.thread_id);
      }

      // Анимация печати входящего сообщения
      animateIncomingMessage(data.message, newMessages);
    } catch (error) {
      console.error('Ошибка при запросе API:', error);
    } finally {
      setIsTyping(false);
    }
  };

  // Функция для анимации печати текста побуквенно
  const animateIncomingMessage = (text, newMessages) => {
    let index = 0;
    const intervalId = setInterval(() => {
      setMessages([
        ...newMessages,
        { text: processText(text.slice(0, index + 1)), fromUser: false },
      ]);
      index++;
      if (msgContainer.current) {
        msgContainer.current.scrollTop = msgContainer.current.scrollHeight;
      }
      if (index === text.length) {
        clearInterval(intervalId);
      }
    }, 5); // Скорость печати в миллисекундах
  };

  const changeContainerHeight = () => {
    setContainerHeight(window.visualViewport.height - 70);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', changeContainerHeight);
  }, []);

  const copyResponse = (message) => {
    navigator.clipboard.writeText(message);
  };

  const popoverContent = (
    <div>
      {preparedMessages.map((item) => {
        return (
          <div
            className={style.preparedMessage_popover_option}
            onClick={() => {
              sendMessage(item);
              setPopoverOpen(false);
            }}
          >
            {item}
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <div
        style={{
          ...styles.chatContainer,
          // height: containerHeight,
          // marginTop: addMargin ? '30%' : 'unset',
        }}
      >
        <div style={styles.messagesContainer} ref={msgContainer}>
          {messages.map((msg, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end',
              }}
            >
              <div
                style={{
                  ...styles.message,
                  ...(msg.fromUser ? styles.userMessage : styles.apiMessage),
                }}
              >
                <p
                  style={styles.messageText}
                  dangerouslySetInnerHTML={{ __html: msg.text }} // Используем dangerouslySetInnerHTML для отображения HTML
                />
                {!msg.fromUser && index !== 0 && (
                  <CopyOutlined
                    onClick={() => copyResponse(msg.text)}
                    style={styles.copyButton}
                  />
                )}
              </div>
            </div>
          ))}
          {isTyping && (
            <div style={styles.typingIndicator}>
              <p style={{ margin: 'unset' }}>...</p>
            </div>
          )}
        </div>
      </div>
      <div style={styles.inputContainer}>
        {preparedMessages.length > 0 ? (
          messages.length === 1 ? (
            <div style={styles.preparedMessages_wrapper}>
              <div
                style={{
                  display: 'flex',
                  columnGap: 12,
                  width:
                    preparedMessages.length * 185 +
                    (preparedMessages.length - 2) * 20,
                }}
              >
                {preparedMessages.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={styles.preparedMessage}
                      onClick={() => sendMessage(item)}
                    >
                      <div style={styles.preparedMessage_badge}>
                        <img src={ai_prep_message} />
                      </div>
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <Popover
              open={popoverOpen}
              content={popoverContent}
              trigger="click"
              overlayInnerStyle={styles.preparedMessage_popover}
            >
              <div
                style={styles.preparedMessage_popover_button}
                onClick={() => setPopoverOpen(!popoverOpen)}
              >
                <img src={ai_prep_bigger} />
              </div>
            </Popover>
          )
        ) : (
          ''
        )}
        <input
          onFocus={() => setAddMargin(true)}
          onBlur={() => setAddMargin(false)}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ваш вопрос..."
          style={styles.input}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage}
        />

        <SendOutlined style={styles.sendButton} onClick={sendMessage} />

        {/* <button onClick={sendMessage} style={styles.sendButton}> */}
        {/* Отправить */}
        {/* </button> */}
      </div>
    </>
  );
}

const styles = {
  chatContainer: {
    width: '100%',
    overflow: 'hidden',
    fontFamily: 'Inter',
    height: '100%',
    transition: '0.3s all',
  },
  messagesContainer: {
    maxHeight: '100%',
    overflowY: 'auto',
  },
  message: {
    marginBottom: '10px',
    padding: '8px 12px',
    maxWidth: '80%',
    display: 'inline-block',
    position: 'relative',
  },
  userMessage: {
    backgroundColor: 'black',
    alignSelf: 'flex-end',
    textAlign: 'left',
    marginLeft: 'auto',
    color: 'white',
    borderRadius: '18px 18px 0px 18px',
  },
  apiMessage: {
    backgroundColor: '#FFF',
    border: '1px solid #e6e6e6',
    color: '#333',
    textAlign: 'left',
    borderRadius: '18px 18px 18px 0px',
  },
  typingIndicator: {
    marginBottom: '10px',
    padding: '8px 12px',
    borderRadius: '18px 18px 18px 0px',
    display: 'inline-block',
    backgroundColor: '#e6e6e6',
    color: '#666',
    fontStyle: 'Inter',
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '24px',
  },
  messageText: {
    margin: 0,
    fontSize: '16px',
    lineHeight: '24px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  input: {
    flex: 1,
    padding: '10px 36px 10px 10px',
    borderRadius: '18px',
    border: '1px solid #ddd',
    outline: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
  },
  sendButton: {
    padding: '8px 12px',
    border: 'none',
    borderRadius: '18px',
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 1,
    right: 0,
    fontSize: 20,
  },
  sendButtonHover: {
    backgroundColor: '#0056b3',
  },
  copyButton: {
    position: 'absolute',
    right: -25,
    color: 'grey',
    fontSize: 20,
    bottom: 0,
  },
  preparedMessages_wrapper: {
    position: 'absolute',
    bottom: '66px',
    width: '100%',
    overflowX: 'scroll',
  },
  preparedMessage: {
    background: '#fff',
    borderRadius: '12px',
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Inter',
    color: '#1B1C1D',
    padding: '15px 10px 10px 10px',
    position: 'relative',
    width: 185,
    marginTop: 15,
    cursor: 'pointer',
  },
  preparedMessage_badge: {
    position: 'absolute',
    left: '50%',
    top: '-15px',
    transform: 'translateX(-50%)',
    background: 'black',
    borderRadius: '100%',
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preparedMessage_popover_button: {
    position: 'absolute',
    top: '-60px',
    right: '0',
    background: 'black',
    borderRadius: '100%',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  preparedMessage_popover: {
    background: '#fff',
    padding: 15,
  },
};

export default ChatV2;
