import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

function EditableLabel(props) {
  const { text, onSave } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(text);

  const _onSave = () => {
    setIsEdit(false);
    onSave(value);
  };

  if (!isEdit) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label>{text}</label>
        <Button
          icon={<EditOutlined />}
          onClick={() => setIsEdit(true)}
          style={{ border: 'unset' }}
          className="no_bg"
        />
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Input value={value} onChange={(e) => setValue(e.target.value)} />
      <Button
        icon={<CheckOutlined />}
        onClick={_onSave}
        style={{ border: 'unset' }}
        className="no_bg"
      />
      <Button
        icon={<CloseOutlined />}
        onClick={() => setIsEdit(false)}
        style={{ border: 'unset' }}
        className="no_bg"
      />
    </div>
  );
}

export default EditableLabel;
